import * as billingApi from "../../../api/billing";
import * as sessionApi from "../../../api/session";
import React, { useState } from "react";
import TableLayout, {
	booleanValueFormatterColour,
} from "../../layout/TableLayout";
import { format, user as userHelpers } from "../../../helpers";
import { useFetchData, usePermissions, useQueryData } from "../../../hooks";
import Card from "../../layout/Card";
import DetailsPageWrapper from "../DetailsPageWrapper";
import FlexWrapper from "../../layout/FlexWrapper";
import ItemDropDownOptions from "../../billing/ItemDropDownOptions";
import Label from "../../../components/layout/Label";
import RefundDetails from "../refund/RefundDetails";
import ReportWrapper from "../../report/ReportWrapper";
import StatCard from "../../layout/StatCard";
import TransactionWizard from "../../wizards/transaction-wizard/TransactionWizard";
import { colours } from "../../../styles";
import gql from "graphql-tag";
import { sortBy } from "lodash";
import moment from "moment";

function formatTimestamp(timestamp) {
	return timestamp
		? moment.unix(timestamp).format("hh:mm A on DD MMM YYYY")
		: "";
}

const transactionHistoryColumns = (_props) => [
	{
		id: "Paid",
		Header: "Paid",
		accessor: (d) => (d.WasVoided ? 0 : d.IsPaid ? 2 : 1), //for sorting
		Cell: (props) =>
			props.original.WasVoided ? (
				<Label color="blue">Void</Label>
			) : (
				booleanValueFormatterColour({ value: !!props.original.IsPaid })
			),
	},
	{
		id: "TransactionID",
		Header: "Transaction ID",
		accessor: "_transactionId",
	},
	{
		id: "PaidOn/DueDate",
		Header: "Paid On/Due Date",
		accessor: (d) => d.LocalTimestamp,
		Cell: (cellProps) => cellProps.original.Date,
	},
	{
		id: "Site",
		Header: "Site",
		accessor: "Site",
	},
	{
		id: "Amount",
		Header: "Amount",
		accessor: "Amount",
		Cell: (cellProps) => {
			const { Amount, WasVoided } = cellProps.original;
			if (WasVoided) {
				return "Void";
			}
			return Amount;
		},
	},
	{
		id: "Plate",
		Header: "Vehicle",
		accessor: "Plate",
	},
	{
		id: "tasks",
		Header: "",
		accessor: null,
		Cell: (cellProps) => (
			<ItemDropDownOptions
				cellProps={cellProps}
				permissions={_props.permissions}
				showTransactionWizard={_props.showTransactionWizard}
				showRefundDetails={_props.showRefundDetails}
			/>
		),
		resizable: false,
		width: 50,
	},
];

const sessionColumns = [
	{
		id: "RateName",
		Header: "Rate",
		accessor: (d) => d.rate.name,
	},
	{
		id: "Duration",
		Header: "Duration",
		accessor: (d) => (d.duration ? format.duration(d.duration) : ""),
	},
	{
		id: "Start",
		Header: "Start",
		accessor: (d) => format.localDate(d.startDateTime, "YYYYMMDDhhmmss"),
	},
	{
		id: "End",
		Header: "End",
		accessor: (d) => format.localDate(d.endDateTime, "YYYYMMDDhhmmss"),
	},
	{
		id: "Fee",
		Header: "Fee",
		Cell: (cellProps) => {
			const { WasVoided, fee } = cellProps.original;
			if (WasVoided) {
				return "Void";
			}

			return format.money(fee);
		},
	},
];

const leaseColumns = [
	{
		id: "Name",
		Header: "Name",
		accessor: (d) => (d.user ? userHelpers.fullName(d.user) : ""),
	},
	{
		id: "PlateNumber",
		Header: "Plate Number",
		accessor: (d) => (d.userLease ? d.userLease.Numberplate || "" : ""),
	},
	{
		id: "DateBooked",
		Header: "Date Booked",
		accessor: (d) =>
			d.userLease && d.userLease.CreatedOn ? d.userLease.CreatedOn : "",
	},
	{
		id: "DateCancelled",
		Header: "Date Cancelled",
		accessor: (d) =>
			d.userLease && d.userLease.CancelledOn ? d.userLease.CancelledOn : "",
	},
];

const productPurchaseColumns = [
	{
		id: "ProductName",
		Header: "Product Name",
		accessor: (d) => d.Product.Name,
	},
	{
		id: "Site",
		Header: "Site",
		accessor: (d) => d.Site.Name,
	},
	{
		id: "PaidOn",
		Header: "Paid On",
		accessor: (d) => format.localDate(d.PaymentRequest.PaidOutOn),
	},
	{
		id: "Fee",
		Header: "Fee",
		accessor: (d) => format.money(d.Fee),
	},
];

const scheduleColumns = () => [
	{
		id: "PaymentId",
		Header: "Payment ID",
		accessor: (d) => d.LeasePaymentID,
	},
	{
		id: "For Date Starting",
		Header: "For Date Starting",
		accessor: (d) => d._forDateStarting,
	},
	{
		id: "Payment Due",
		Header: "Payment Due",
		accessor: (d) => format.localDate(d.dueDate, "YYYYMMDDhh", true),
	},
	{
		id: "Amount",
		Header: "Amount",
		accessor: (d) => format.money(d.Fee),
	},
	{
		id: "Paid",
		Header: "Paid",
		accessor: (d) => (d.IsPaid ? "Yes" : "No"),
	},
];

function useGetExternalSession(externalParkingSessionId, rowData) {
	const {
		data: { getExternalParkingSession: session },
		isLoading,
		refetch: refetchExternalSession,
	} = useQueryData(
		gql`
			query ($externalParkingSessionId: Int!) {
				getExternalParkingSession(
					externalParkingSessionId: $externalParkingSessionId
				) {
					StartTime
					ExpiryTime
					Plate
					DurationSeconds
					Transaction {
						ExternalID
						TxnTime
						TxnExpiry
						Amount
						TxnType
						Rate {
							ExternalRateID
							Name
						}
					}
					RefundRequests {
						CreatedOn
						RefundRequestID
						Amount
						DidSucceed
					}
				}
			}
		`,
		{ externalParkingSessionId },
		!externalParkingSessionId
	);

	if (!session) {
		return {
			isLoading,
			externalSession: null,
			transactionHistory: [],
			refetchExternalSession,
		};
	}

	const transactionHistory = [];
	if (session.Transaction) {
		transactionHistory.push({
			...rowData,
			Site: rowData.SiteNames,
			Amount: format.money(session.Transaction.Amount),
			LocalTimestamp: session.Transaction.TxnTime,
			Date: formatTimestamp(session.Transaction.TxnTime),
			Plate: session.Plate,
			Rate: session.Transaction?.Rate,
			IsPaid: true,
			WasVoided: false,
			_itemType: rowData.ItemTypeFormatted,
			_transactionId: `MPS-${session.Transaction.ExternalID}`,
		});
	}

	for (const refund of session.RefundRequests?.filter((rr) => rr.DidSucceed) ??
		[]) {
		transactionHistory.push({
			Site: rowData.SiteNames,
			WasVoided: false,
			IsPaid: true,
			RefundRequestID: refund.RefundRequestID,
			LocalTimestamp: refund.CreatedOn,
			Date: formatTimestamp(refund.CreatedOn),
			Amount: format.money(refund.Amount),
			Plate: session.Plate,
			_transactionId: `MPS-${refund.RefundRequestID}`,
		});
	}

	return {
		isLoading,
		refetchExternalSession,
		transactionHistory,
		externalSession: {
			...session,
			StartTimeDisplay: formatTimestamp(session.StartTime),
			ExpiryTimeDisplay: formatTimestamp(session.ExpiryTime),
		},
	};
}

export default function TransactionDetails(props) {
	const [state, setState] = useState({
		transactionWizardOpen: false,
		refundDetailsOpen: false,
	});

	let parkingSessionId = null;
	let userLeaseId = null;

	if (props.session) parkingSessionId = props.session.ParkingSessionID;
	if (props.lease) userLeaseId = props.lease.UserLeaseID;

	const canRefund = usePermissions(null, "RefundUser", true);
	const canResendReceipt = usePermissions(null, "ResendReceipt", true);
	const isAdmin = usePermissions("IsAdmin");
	const canManageSessions = usePermissions(null, "SessionAdmin", true);

	//transactions
	let { data: transactionHistoryData, isLoading: transactionHistoryLoading } =
		useFetchData(
			[],
			parkingSessionId
				? billingApi.getParkingHistoryBySession
				: userLeaseId
				? billingApi.getParkingHistoryByLease
				: null,
			parkingSessionId
				? [props.siteId, parkingSessionId]
				: [props.siteId, userLeaseId],
			parkingSessionId
				? [props.siteId, parkingSessionId, state.transactionWizardOpen]
				: [props.siteId, userLeaseId, state.transactionWizardOpen]
		);

	// session
	let parkingSessionData = [];

	const { data: sessionData, isLoading: sessionLoading } = useFetchData(
		{ feeData: {} },
		parkingSessionId ? sessionApi.getSession : null,
		[props.siteId, parkingSessionId],
		[props.siteId, parkingSessionId, state.transactionWizardOpen]
	);

	if (parkingSessionId) {
		const {
			entryDate,
			exitDate,
			FinalFee,
			ConsumerServiceFee,
			AdditionalOperatorFee,
			feeData: { charges, validationDiscount, promotionDiscount },
		} = sessionData;

		const _charges = [
			...(sortBy(charges, (e) => e.startDateTime) || []),
			{
				fee: FinalFee,
				rate: {
					name: "Total",
				},
			},
		];

		if (ConsumerServiceFee) {
			_charges.splice(-1, 0, {
				fee: ConsumerServiceFee,
				rate: {
					name: "Convenience Fee",
				},
			});
		}

		if (AdditionalOperatorFee) {
			_charges.splice(-1, 0, {
				fee: AdditionalOperatorFee,
				rate: {
					name: "Credit Card Fee",
				},
			});
		}

		if (validationDiscount || promotionDiscount) {
			const discount = validationDiscount ?? promotionDiscount;
			if (discount) {
				_charges.splice(-1, 0, {
					fee: -discount,
					rate: {
						name: "Discount",
					},
				});
			}
		}

		parkingSessionData = {
			charges: _charges,
			totalCost: sessionData.VoidedOn ? "Void" : format.money(FinalFee),
			started: format.localDate(entryDate, "YYYYMMDDhhmmss"),
			ended: format.localDate(exitDate, "YYYYMMDDhhmmss"),
		};
	}

	// lease
	let userLeaseData = [];
	let BeginDate;
	let EndDate;
	let TotalCharged = 0;

	const { data: leaseData, isLoading: leaseLoading } = useFetchData(
		{ feeData: {} },
		userLeaseId ? sessionApi.getUserLease : null,
		[props.siteId, userLeaseId],
		[props.siteId, userLeaseId, state.transactionWizardOpen]
	);

	if (userLeaseId) {
		let { leasePayments, user, userLease } = leaseData;

		if (userLease && leasePayments) {
			if (!user) {
				user = {
					Email: userLease.UnregisteredEmail,
					FirstName: userLease.UnregisteredFirstName,
					LastName: userLease.UnregisteredLastName,
				};
			}

			BeginDate = format.localDate(
				userLease.StartDateTime,
				"YYYYMMDDHHmm",
				userLease.CachedDurationType !== "Hours"
			);
			EndDate = format.localDate(
				userLease.EndDateTime,
				"YYYYMMDDHHmm",
				userLease.CachedDurationType !== "Hours",
				false,
				userLease.CachedDurationType === "Hours"
			);

			for (let leasePayment of leasePayments) {
				if (leasePayment.IsPaid) {
					TotalCharged += leasePayment.Fee;
				}
			}

			TotalCharged = format.money(TotalCharged);
		}

		userLeaseData = {
			lease: [
				{
					user: user,
					userLease: userLease,
				},
			],
			leasePayments: leasePayments,
		};
	}

	const {
		data: { getProductPurchase },
		isLoading: isLoadingProductPurchase,
	} = useQueryData(
		gql`
			query ($productPurchaseId: Int!) {
				getProductPurchase(productPurchaseId: $productPurchaseId) {
					ProductPurchaseID
					Fee
					PaymentRequest {
						PaidOutOn
					}
					Product {
						ProductID
						Name
					}
					Site {
						SiteID
						Name
					}
				}
			}
		`,
		{ productPurchaseId: parseInt(props.productPurchaseId) },
		!props.productPurchaseId
	);

	const {
		isLoading: isLoadingExternalSession,
		refetchExternalSession,
		transactionHistory: externalSessionTrnHistory,
		externalSession,
	} = useGetExternalSession(
		parseInt(props.externalParkingSessionId),
		props.row
	);

	if (externalSession) {
		transactionHistoryData = externalSessionTrnHistory;
	}

	const user = props.user ?? sessionData?.user ?? leaseData?.user;

	function openTransactionWizard(mode, transaction) {
		setState((_state) => ({
			..._state,
			transactionWizardOpen: true,
			transactionWizardMode: mode,
			transactionWizardTransaction: transaction,
		}));
	}

	function showRefundDetails(siteId, refundRequestId) {
		setState((_state) => ({
			..._state,
			selectedSiteId: siteId,
			selectedRefundRequestId: refundRequestId,
			refundDetailsOpen: true,
		}));
	}

	if (state.transactionWizardOpen) {
		return (
			<TransactionWizard
				close={() => {
					setState((_state) => ({
						..._state,
						transactionWizardOpen: false,
					}));
					refetchExternalSession();
				}}
				mode={state.transactionWizardMode}
				transaction={state.transactionWizardTransaction}
				selectedOrganization={props.selectedOrganization}
			/>
		);
	}

	if (state.refundDetailsOpen) {
		return (
			<RefundDetails
				{...props}
				siteId={state.selectedSiteId}
				refundRequestId={state.selectedRefundRequestId}
				onClose={() => {
					setState((_state) => ({
						..._state,
						refundDetailsOpen: false,
					}));
				}}
			/>
		);
	}

	const content = (
		<div>
			{user?.Email && (
				<Card>
					<h2>User Information</h2>
					<TableLayout
						data={[user]}
						columns={[
							{
								id: "Name",
								Header: "Name",
								accessor: (d) => d.Name ?? `${d.FirstName} ${d.LastName}`,
								resizable: false,
							},
							{
								id: "Email",
								Header: "Email",
								accessor: "Email",
								resizable: false,
							},
						]}
						sortable={false}
					/>
				</Card>
			)}
			{!props.productPurchaseId && (
				<Card>
					<h2>Transaction History</h2>
					<TableLayout
						{...props}
						data={transactionHistoryData}
						columns={transactionHistoryColumns({
							permissions: {
								canRefund,
								canResendReceipt,
								isAdmin,
								canManageSessions,
							},
							showTransactionWizard: openTransactionWizard,
							showRefundDetails: showRefundDetails,
							showParkingSessionCharges: props.showParkingSessionCharges,
						})}
						loading={transactionHistoryLoading || isLoadingExternalSession}
						getTrProps={(_state, rowInfo) => {
							if (rowInfo && rowInfo.original) {
								if (rowInfo.original.RefundRequestID) {
									return { style: { color: `${colours.red}` } };
								} else {
									return { style: { color: "inherit" } };
								}
							}
						}}
					/>
				</Card>
			)}
			{externalSession && (
				<Card>
					<h2>Meter Parking Session Charges</h2>
					<ReportWrapper
						{...props}
						data={[
							{
								Duration: externalSession.DurationSeconds,
								StartTime: externalSession.StartTimeDisplay,
								EndTime: externalSession.ExpiryTimeDisplay,
								Fee: props.row._totalAmount,
								Rate: externalSession.Transaction?.Rate,
							},
						]}
						columns={[
							{
								id: "Rate",
								Header: "Rate",
								accessor: (d) => d.Rate?.Name ?? "Unknown",
							},
							{
								id: "Duration",
								Header: "Duration",
								accessor: (d) =>
									d.Duration ? format.duration(d.Duration) : "",
							},
							{
								id: "Start",
								Header: "Start",
								accessor: (d) => d.StartTime,
							},
							{
								id: "End",
								Header: "End",
								accessor: (d) => d.EndTime,
							},
							{
								id: "Fee",
								Header: "Fee",
								accessor: (d) => d.Fee,
							},
						]}
						updateOptions={() => {}}
						loading={isLoadingExternalSession}
						showSitePicker={false}
						showDateRangePicker={false}
						showSearchBox={false}
						showResultsLength={false}
						stats={
							<FlexWrapper style={{ marginBottom: -16 }}>
								<Card>
									<StatCard
										value={externalSession.StartTimeDisplay}
										title="Started"
										size="mini"
									/>
								</Card>
								<Card>
									<StatCard
										value={externalSession.ExpiryTimeDisplay}
										title="Ended"
										size="mini"
									/>
								</Card>
								<Card>
									<StatCard
										value={props.row._totalAmount}
										title="Total Cost"
										size="mini"
									/>
								</Card>
							</FlexWrapper>
						}
					/>
				</Card>
			)}
			{props.session && (
				<Card>
					<h2>
						{sessionData.VesselID ? "Mooring" : "Parking"} Session Charges
					</h2>
					<ReportWrapper
						{...props}
						data={parkingSessionData.charges}
						columns={sessionColumns}
						updateOptions={() => {}}
						loading={sessionLoading}
						showSitePicker={false}
						showDateRangePicker={false}
						showSearchBox={false}
						showResultsLength={false}
						stats={
							parkingSessionData.ended ? (
								<FlexWrapper style={{ marginBottom: -16 }}>
									<Card>
										<StatCard
											value={parkingSessionData.started}
											title="Started"
											size="mini"
										/>
									</Card>
									<Card>
										<StatCard
											value={parkingSessionData.ended}
											title="Ended"
											size="mini"
										/>
									</Card>
									<Card>
										<StatCard
											value={parkingSessionData.totalCost}
											title="Total Cost"
											size="mini"
										/>
									</Card>
								</FlexWrapper>
							) : null
						}
					/>
				</Card>
			)}
			{props.lease && (
				<Card>
					<h2>Booking Details</h2>
					<ReportWrapper
						{...props}
						availableSites={[]}
						data={userLeaseData.lease}
						columns={leaseColumns}
						updateOptions={() => {}}
						loading={leaseLoading}
						showSitePicker={false}
						showDateRangePicker={false}
						showSearchBox={false}
						showResultsLength={false}
						stats={
							<FlexWrapper style={{ marginBottom: -16 }}>
								<Card>
									<StatCard value={BeginDate} title="Started" size="mini" />
								</Card>
								<Card>
									<StatCard value={EndDate} title="Ended" size="mini" />
								</Card>
								<Card>
									<StatCard
										value={TotalCharged}
										title="Total Charged"
										size="mini"
									/>
								</Card>
							</FlexWrapper>
						}
					/>
					<Card>
						<h3>Payments Schedule</h3>
						<TableLayout
							data={userLeaseData.leasePayments}
							columns={scheduleColumns({
								durationType: leaseData.userLease
									? leaseData.userLease.CachedDurationType
									: null,
							})}
						/>
					</Card>
				</Card>
			)}
			{props.productPurchaseId && (
				<Card>
					<h2>Product Purchase Details</h2>
					<ReportWrapper
						{...props}
						data={[getProductPurchase]}
						columns={productPurchaseColumns}
						updateOptions={() => {}}
						loading={isLoadingProductPurchase}
						showSitePicker={false}
						showDateRangePicker={false}
						showSearchBox={false}
						showResultsLength={false}
					/>
				</Card>
			)}
		</div>
	);

	return (
		<DetailsPageWrapper
			close={props.onClose}
			content={content}
			title="Transaction Details"
		/>
	);
}
