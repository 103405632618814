import * as Yup from "yup";
import _ from "lodash";
import * as steps from "./steps";
import * as genericSteps from "../Generics";
import React from "react";
import WizardLayout from "../WizardLayout";
import gql from "graphql-tag";
import { useMutateData } from "../../../hooks";
import Alert from "react-s-alert";
import { GENERIC_INPUT_TYPES } from "../../../helpers/constants";

export default function VariableWizard(props) {
	const variable = props.variable;
	const isAdding = props.mode === "add";
	const questions = [
		{
			key: "Name",
			question: "What is the name of this variable?",
			validate: (value) => !!value,
		},
		{
			key: "Type",
			question: "What is the type of this variable?",
			validate: (value) => !!value,
			options: ["String", "Integer", "Float"].map((t) => ({
				value: t,
				label: t,
			})),
			type: GENERIC_INPUT_TYPES.SELECT,
		},
		{ key: "Label", question: "What is the label for this variable?" },
		{
			key: "Value",
			question: "What is the value of this variable?",
			secrifiable: true,
			suggestable: true,
		},
		{
			key: "Description",
			question: "What is the description of this variable?",
		},
	];
	const currentSteps = [
		steps.secretStep,
		...questions.map((question) => genericSteps.inputStep(question)),
		genericSteps.summaryStep(questions),
	];

	const requestBasedVariables = props.requests
		? _.uniqBy(
				_.flatten(
					props.requests
						.map((request) =>
							request.Response?.map((resp) => ({
								value: resp.Variable,
								label: resp.Label,
								description: resp.Description,
								type: resp.Type,
							}))
						)
						.filter((v) => v)
				),
				"value"
		  )
		: [];

	const values = [
		{
			name: "isSecret",
			value: variable?.IsSecret ? "Yes" : "No",
			validator: Yup.string(),
		},
		...questions.map((question) => ({
			name: question.key.toLowerCase(),
			value: variable?.[question.key] || "",
			validator: Yup.string(),
		})),
		{
			name: "variableGroups",
			value: [
				{
					name: "Requests",
					variables: requestBasedVariables,
					color: "orange",
				},
				...(props.variableGroups?.map((variableGroup) => ({
					name: variableGroup.Name,
					color: variableGroup.Name === "Custom" ? "blue" : "default",
					variables: variableGroup.Variables?.map((v) => ({
						value: v.Name,
						variableValue: v.Value,
						label: v.Label,
						description: v.Description,
						type: v.Type,
					})),
				})) || []),
			],
			validator: Yup.array(),
		},
	];

	const createVariable = useMutateData(gql`
		mutation (
			$context: IntegrationContext!
			$organizationId: Int
			$name: String!
			$label: String
			$value: String
			$description: String
			$type: VariableType!
			$isSecret: Boolean
		) {
			createVariable(
				context: $context
				organizationId: $organizationId
				name: $name
				label: $label
				value: $value
				description: $description
				type: $type
				isSecret: $isSecret
			)
		}
	`);

	const updateVariable = useMutateData(gql`
		mutation (
			$variableId: Int!
			$name: String!
			$label: String
			$value: String
			$description: String
			$type: VariableType!
			$isSecret: Boolean
		) {
			updateVariable(
				variableId: $variableId
				name: $name
				label: $label
				value: $value
				description: $description
				type: $type
				isSecret: $isSecret
			)
		}
	`);

	const deleteVariable = useMutateData(gql`
		mutation ($variableId: Int!) {
			deleteVariable(variableId: $variableId)
		}
	`);

	const onSubmit = async (data) => {
		try {
			if (props.mode === "delete") {
				await deleteVariable({
					variables: {
						variableId: variable.VariableID,
					},
				});
				props.close();
				return;
			}

			const base = {
				name: data.name,
				label: data.label,
				value: data.value,
				description: data.description,
				type: data.type,
				isSecret: data.isSecret === "Yes",
			};
			if (props.mode === "add") {
				await createVariable({
					variables: {
						context: props.context,
						organizationId: props.organizationId,
						...base,
					},
				});
			} else if (props.mode === "edit") {
				await updateVariable({
					variables: {
						variableId: variable.VariableID,
						...base,
					},
				});
			}

			Alert.success(`${isAdding ? "Added" : "Updated"} Variable`);
		} catch (error) {
			Alert.error("Something went wrong.");
		}
		props.close();
	};

	if (props.mode === "delete") {
		return (
			<WizardLayout
				rootTabIndex={null}
				close={props.close}
				title={"Delete Variable"}
				onSubmit={onSubmit}
				values={values}
				steps={[steps.deleteStep]}
				initialStep={0}
				wizardProps={props}
			/>
		);
	}

	return (
		<WizardLayout
			rootTabIndex={null}
			close={props.close}
			title={`${isAdding ? "Add" : "Edit"} Variable`}
			onSubmit={onSubmit}
			values={values}
			steps={currentSteps}
			initialStep={isAdding ? 0 : currentSteps.length - 1}
			wizardProps={props}
		/>
	);
}
