import {
	COLORS_BY_REQUEST_METHOD,
	EVENT_TYPES_BY_INTEGRATION_TYPE,
	EVENT_TYPES_TRANSLATIONS,
	INTEGRATION_TYPES,
	INTEGRATION_TYPES_TRANSLATIONS,
	CREATE_METHODS,
	CREATE_METHOD_BY_KEY,
	CREATE_METHOD_KEYS,
	INTEGRATION_CONTEXT,
	EVENT_TYPES,
} from "../../../helpers/constants";
import { StepText, StepTitle } from "../WizardLayout";
import Button from "../../layout/Button";
import Dropdown from "../../layout/Dropdown";
import MultiChoiceButton from "../MultiChoiceButton";
import { useCallback } from "react";
import SummaryTable from "../SummaryTable";
import TableLayout from "../../layout/TableLayout";
import WizardNavigation from "../WizardNavigation";
import Label from "../../layout/Label";
import Input from "../../layout/Input";
import Repeater, { Icon } from "../../layout/Repeater";
import { colours } from "../../../styles";
import { PlusCircle } from "react-feather";
import InputWithIcon from "../../layout/InputWithIcon";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";
import InfoBox from "../../layout/InfoBox";

const DropBox = styled.div`
	background: ${colours.highlightGrey};
	border: 4px dashed ${colours.borderGrey};
	border-radius: 4px;
	margin-bottom: 10px;
	padding: 60px 10px;
	text-align: center;
	width: 100%;

	p {
		font-size: 18px;
		font-weight: 600;
	}

	&.dragover-err {
		border: 5px dashed $red;
	}

	&.dragover {
		border: 5px dashed $green;
	}
`;

export const createMethodStep =
	(setCreateMethod, setIntegrationType, setHasVariables) =>
	({ values, setFieldValue, next, goTo, close, wizardProps }) => {
		const populateIntegrationWithContent = (importContent) => {
			const integration = importContent.Integration;
			if (integration) {
				const request = importContent.Requests.find(
					(r) => r.RequestID === integration.RequestUUID
				);
				const mappedRequest = {
					label: request.Name,
					value: request.RequestID,
					url: request.URL,
					method: request.Method,
				};
				setFieldValue("request", mappedRequest);
				setFieldValue("requests", [mappedRequest]);
				setFieldValue("integrationType", integration.IntegrationType);
				setFieldValue("eventType", integration.EventType);
				setFieldValue("directory", integration.Metadata?.Directory);
				setFieldValue(
					"headers",
					integration.Metadata?.CSVConfig?.Headers?.map((header) => ({
						column: header.Header,
						required: header.Required,
					}))
				);
				setFieldValue("isSynchronous", Boolean(integration.IsSynchronous));

				const columns = [
					{ name: "externalUserCode", prop: "ExternalUserCode" },
					{ name: "siteId", prop: "SiteID" },
					{ name: "groupName", prop: "GroupName" },
				];

				for (let column of columns) {
					const prop = integration.Metadata?.CSVConfig?.[column.prop];
					setFieldValue(
						column.name,
						prop
							? {
									column: prop.Column,
							  }
							: null
					);
				}

				setFieldValue(
					"valueMappings",
					integration.Metadata?.CSVConfig?.MappedValues
						? integration.Metadata?.CSVConfig?.MappedValues?.map((value) => ({
								column: value.Column,
								from: value.From,
								to: value.To,
						  }))
						: null
				);

				setFieldValue("importContent", importContent);

				setHasVariables(
					importContent.Variables ? importContent.Variables.length > 0 : false
				);
				setIntegrationType(integration.IntegrationType);

				goTo("summary");
			}
		};

		const onDrop = useCallback(async (acceptedFiles) => {
			const file = acceptedFiles ? acceptedFiles[0] : null;
			const text = await file.text();
			const importContent = JSON.parse(text);

			populateIntegrationWithContent(importContent);
		}, []);
		const { getRootProps, getInputProps, isDragActive } = useDropzone({
			onDrop,
		});
		return {
			id: "createMethod",
			label: "Create",
			render: () => (
				<div>
					<StepTitle>How do you want to create this integration?</StepTitle>

					{CREATE_METHODS.map((createMethod) => (
						<MultiChoiceButton
							key={createMethod.value}
							selected={values.createMethod === createMethod.value}
							onClick={() => {
								setFieldValue("createMethod", createMethod.value);
								setCreateMethod(createMethod.value);
							}}
						>
							{createMethod.label}
						</MultiChoiceButton>
					))}

					{values.createMethod === CREATE_METHOD_KEYS.IMPORT && (
						<DropBox {...getRootProps()}>
							<input {...getInputProps()} multiple={false} accept="text/json" />
							{isDragActive && <p>Drop the file here...</p>}
							{!values.importFile && !isDragActive && (
								<p>Click to choose a file or drag one here...</p>
							)}
							{values.importFile && !isDragActive && (
								<p>File chosen: {values.importFile.name}</p>
							)}
						</DropBox>
					)}

					{values.createMethod === CREATE_METHOD_KEYS.TEMPLATE && (
						<>
							<StepText>Template</StepText>
							<Dropdown
								style={{ marginTop: "10px" }}
								options={(wizardProps.templates || []).map((template) => ({
									value: template.IntegrationTemplateID,
									label: template.Name,
									template: template.Template,
								}))}
								value={values.selectedTemplate}
								onChange={(value) => {
									setFieldValue("selectedTemplate", value);
									const importContent = JSON.parse(value.template);

									populateIntegrationWithContent(importContent);
								}}
							/>
						</>
					)}
				</div>
			),
			footer: () => {
				return (
					<WizardNavigation
						leftItems={
							values.editFromSummary
								? []
								: [
										<Button key="submit" color="blue" onClick={close}>
											Cancel
										</Button>,
								  ]
						}
						rightItems={[
							<Button
								key="submit"
								color="blue"
								onClick={() => {
									if (values.editFromSummary) {
										goTo("summary");
									} else {
										next();
									}
								}}
							>
								{values.editFromSummary ? "Review" : "Next"}
							</Button>,
						]}
					/>
				);
			},
		};
	};

export const integrationTypeStep =
	(setIntegrationType) =>
	({ values, setFieldValue, next, goTo, previous }) => {
		return {
			id: "integrationType",
			label: "Integration Type",
			render: () => (
				<div>
					<StepTitle>What type of integration is this for?</StepTitle>

					{[
						INTEGRATION_TYPES.WEBHOOK,
						INTEGRATION_TYPES.POLLING,
						INTEGRATION_TYPES.FILE_TRANSFER,
					].map((integrationType) => (
						<MultiChoiceButton
							key={integrationType}
							selected={values.integrationType === integrationType}
							onClick={() => {
								setFieldValue("integrationType", integrationType);
								setIntegrationType(integrationType);
							}}
						>
							{INTEGRATION_TYPES_TRANSLATIONS[integrationType]}
						</MultiChoiceButton>
					))}
				</div>
			),
			footer: () => {
				return (
					<WizardNavigation
						leftItems={
							values.editFromSummary
								? []
								: [
										<Button key="submit" color="blue" onClick={previous}>
											Back
										</Button>,
								  ]
						}
						rightItems={[
							<Button
								key="submit"
								color="blue"
								onClick={() => {
									if (values.editFromSummary) {
										goTo("summary");
									} else {
										next();
									}
								}}
							>
								{values.editFromSummary ? "Review" : "Next"}
							</Button>,
						]}
					/>
				);
			},
		};
	};

export const eventTypeStep =
	(setEventType) =>
	({ values, setFieldValue, next, previous, goTo, wizardProps }) => {
		const integrationType = values.integrationType;
		const groupedOptions = EVENT_TYPES_BY_INTEGRATION_TYPE[integrationType];
		const selectedEventType = groupedOptions
			?.reduce((acc, group) => [...acc, ...group.options], [])
			?.find((et) => et.value === values.eventType);

		const selectedBranding = wizardProps?.brands?.find(
			(et) => values?.brandId === et?.value
		);

		const verificationBooking = [EVENT_TYPES.VERIFICATION_BOOKING].includes(
			values?.eventType
		);
		return {
			id: "eventType",
			label: "Event Type",
			render: () => (
				<div>
					<StepTitle>What event is this integration for?</StepTitle>

					<Dropdown
						options={groupedOptions}
						value={selectedEventType}
						onChange={(value) => {
							setFieldValue("eventType", value?.value);
							setEventType(value?.value);
						}}
					/>
					{verificationBooking ? (
						<div style={{ marginTop: "1rem" }}>
							<StepTitle>Branding</StepTitle>
							<Dropdown
								placeholder="Branding"
								options={wizardProps?.brands}
								value={selectedBranding}
								onChange={(value) => {
									setFieldValue("brandId", value?.value);
								}}
							/>
						</div>
					) : null}
				</div>
			),
			footer: () => {
				return (
					<WizardNavigation
						leftItems={
							values.editFromSummary
								? []
								: [
										<Button key="submit" color="blue" onClick={previous}>
											Back
										</Button>,
								  ]
						}
						rightItems={[
							<Button
								key="right-button"
								color="blue"
								disabled={verificationBooking && !values.brandId}
								onClick={() => {
									if (values.editFromSummary) {
										if (
											wizardProps.context === "Client" &&
											!values.selectedSites?.length
										) {
											setFieldValue("editFromSummary", true);
											goTo("selectSites");
											return;
										}

										goTo("summary");
									} else {
										next();
									}
								}}
							>
								{values.editFromSummary ? "Review" : "Next"}
							</Button>,
						]}
					/>
				);
			},
		};
	};

export const deleteStep = ({ handleSubmit, isSubmitting, wizardProps }) => {
	const leaseRates = wizardProps.integration.LeaseRates || [];
	return {
		id: "delete",
		label: "Delete",
		render: () => (
			<div>
				<StepText>
					{leaseRates.length > 0 ? (
						<div>
							<p>
								Unable to delete integration as it is linked to the following
								booking rate(s):
							</p>
							<ul>
								{leaseRates.map((rate, index) => (
									<li key={index}>{rate.Name}</li>
								))}
							</ul>
						</div>
					) : (
						"Are you sure you want to delete this integration? This action CANNOT be undone."
					)}
				</StepText>
			</div>
		),
		footer: () => (
			<WizardNavigation
				leftItems={[
					<Button key="cancel" color="blue" onClick={wizardProps.close}>
						Cancel
					</Button>,
				]}
				rightItems={
					leaseRates.length === 0
						? [
								<Button
									key="submit"
									color="red"
									onClick={handleSubmit}
									disabled={isSubmitting}
								>
									Delete
								</Button>,
						  ]
						: []
				}
			/>
		),
	};
};

export const toggleStep =
	(isEnabled) =>
	({ handleSubmit, isSubmitting, wizardProps }) => ({
		id: "toggle",
		label: "Enable",
		render: () => (
			<div>
				<StepText>
					Are you sure you want to {isEnabled ? "disable" : "enable"} this
					integration?
				</StepText>
			</div>
		),
		footer: () => (
			<WizardNavigation
				leftItems={[
					<Button key="cancel" color="blue" onClick={wizardProps.close}>
						Cancel
					</Button>,
				]}
				rightItems={[
					<Button
						key="submit"
						color={isEnabled ? "yellow" : "green"}
						onClick={handleSubmit}
						disabled={isSubmitting}
					>
						{isEnabled ? "Disable" : "Enable"}
					</Button>,
				]}
			/>
		),
	});

export function selectContextStep(name) {
	return ({
		keyStrokeHandler,
		setFieldValue,
		goTo,
		values,
		previous,
		next,
	}) => {
		const allLabel = `All ${name}`;
		const allOption = { value: null, label: allLabel };
		const selectedDataKey = `selected${name}`;
		return {
			id: `select${name}`,
			label: name,
			render: () => {
				return (
					<div>
						<StepTitle>
							What {name.toLowerCase()} should apply to this integration?
						</StepTitle>
						<Dropdown
							isMulti={true}
							options={[allOption, ...values[`available${name}`]]}
							value={values[selectedDataKey]}
							onChange={(value) => {
								const findAll = (option) => option.label === allLabel;
								const hasSelectedAll =
									value.find(findAll) && !values[selectedDataKey].find(findAll);

								if (hasSelectedAll) {
									setFieldValue(selectedDataKey, [allOption]);
								} else {
									setFieldValue(
										selectedDataKey,
										value.filter((option) => option.value)
									);
								}
							}}
						/>
					</div>
				);
			},
			footer: () => {
				return (
					<WizardNavigation
						leftItems={
							values.editFromSummary
								? []
								: [
										<Button key="submit" color="blue" onClick={previous}>
											Back
										</Button>,
								  ]
						}
						rightItems={[
							<Button
								key="submit"
								color="blue"
								onClick={() => {
									if (values.editFromSummary) {
										goTo("summary");
									} else {
										next();
									}
								}}
								disabled={values[selectedDataKey]?.length ? false : true}
								keyStrokeHandler={keyStrokeHandler}
							>
								{values.editFromSummary ? "Review" : "Next"}
							</Button>,
						]}
					/>
				);
			},
		};
	};
}

export function requestStep({ setFieldValue, goTo, values, previous, next }) {
	return {
		id: "request",
		label: "Request",
		render: () => {
			return (
				<div>
					<StepTitle>Request</StepTitle>
					<Dropdown
						options={values.requests}
						value={values.request}
						onChange={(value) => {
							setFieldValue("request", value);
						}}
					/>
				</div>
			);
		},
		footer: () => {
			return (
				<WizardNavigation
					leftItems={
						values.editFromSummary
							? []
							: [
									<Button key="submit" color="blue" onClick={previous}>
										Back
									</Button>,
							  ]
					}
					rightItems={[
						<Button
							key="submit"
							color="blue"
							onClick={() => {
								if (values.editFromSummary) {
									goTo("summary");
								} else {
									next();
								}
							}}
						>
							{values.editFromSummary ? "Review" : "Next"}
						</Button>,
					]}
				/>
			);
		},
	};
}

export function variablesStep({ setFieldValue, goTo, values, previous, next }) {
	return {
		id: "variables",
		label: "Variables",
		render: () => {
			return (
				<div>
					<StepTitle>What are the values for these variables?</StepTitle>
					{values.importContent?.Variables?.map((variable, index) => (
						<div key={variable.Name} style={{ padding: "8px" }}>
							<h3>{variable.Label}</h3>
							<InputWithIcon
								value={variable.Value}
								onFormat={(value) => {
									setFieldValue(
										`importContent.Variables.${index}.Value`,
										value
									);
								}}
								width="250px"
								tip={variable.Description}
							/>
						</div>
					))}
				</div>
			);
		},
		footer: () => {
			return (
				<WizardNavigation
					leftItems={
						values.editFromSummary
							? []
							: [
									<Button key="submit" color="blue" onClick={previous}>
										Back
									</Button>,
							  ]
					}
					rightItems={[
						<Button
							key="submit"
							color="blue"
							onClick={() => {
								if (values.editFromSummary) {
									goTo("summary");
								} else {
									next();
								}
							}}
						>
							{values.editFromSummary ? "Review" : "Next"}
						</Button>,
					]}
				/>
			);
		},
	};
}

export const directoryStep = ({
	values,
	setFieldValue,
	next,
	goTo,
	previous,
}) => {
	return {
		id: "directory",
		label: "Directory",
		render: () => (
			<div>
				<StepTitle>What directory do you want us to pull from?</StepTitle>

				<Input
					type="text"
					name="directory"
					value={values.directory || ""}
					onChange={(event) => {
						setFieldValue("directory", event.target.value);
					}}
				/>
			</div>
		),
		footer: () => {
			return (
				<WizardNavigation
					leftItems={
						values.editFromSummary
							? []
							: [
									<Button key="submit" color="blue" onClick={previous}>
										Back
									</Button>,
							  ]
					}
					rightItems={[
						<Button
							key="submit"
							color="blue"
							onClick={() => {
								if (values.editFromSummary) {
									goTo("summary");
								} else {
									next();
								}
							}}
							disabled={!values.directory}
						>
							{values.editFromSummary ? "Review" : "Next"}
						</Button>,
					]}
				/>
			);
		},
	};
};

export const headersStep = ({
	values,
	setFieldValue,
	next,
	goTo,
	previous,
}) => {
	return {
		id: "headers",
		label: "Headers",
		render: () => (
			<div>
				<StepTitle>What are the expected CSV headers?</StepTitle>
				<>
					<div
						style={{
							display: "flex",
							marginBottom: "15px",
							width: "300px",
						}}
					>
						<StepText
							style={{
								marginBottom: "20px",
								width: "200px",
								marginRight: "20px",
							}}
						>
							Header
						</StepText>
						<StepText style={{ marginBottom: "20px" }}>Required</StepText>
					</div>
					<Repeater
						minusMode="all"
						items={values.headers || []}
						add={() => {
							setFieldValue("headers", [
								...values.headers,
								{ column: "", required: false },
							]);
						}}
						subtract={() => {
							const items = [...values.headers];
							items.pop();
							setFieldValue("headers", items);
						}}
						template={(header, index) => {
							return (
								<div
									key={`header${index}`}
									style={{
										display: "flex",
										marginBottom: "15px",
										width: "300px",
									}}
								>
									<Input
										style={{ width: "200px" }}
										key={`header${index}Column`}
										value={header.column}
										onChange={(event) => {
											setFieldValue(
												`headers.${index}.column`,
												event.target.value
											);
										}}
									/>
									<Input
										style={{
											width: "20px",
											height: "20px",
											marginLeft: "40px",
											marginTop: "15px",
										}}
										key={`header${index}Required`}
										type="checkbox"
										checked={header.required}
										onChange={(event) => {
											setFieldValue(
												`headers.${index}.required`,
												event.target.checked
											);
										}}
									/>
								</div>
							);
						}}
					/>
				</>
			</div>
		),
		footer: () => {
			return (
				<WizardNavigation
					leftItems={
						values.editFromSummary
							? []
							: [
									<Button key="submit" color="blue" onClick={previous}>
										Back
									</Button>,
							  ]
					}
					rightItems={[
						<Button
							key="submit"
							color="blue"
							onClick={() => {
								if (values.editFromSummary) {
									goTo("summary");
								} else {
									next();
								}
							}}
							disabled={values.hasParams && !values.params?.length}
						>
							{values.editFromSummary ? "Review" : "Next"}
						</Button>,
					]}
				/>
			);
		},
	};
};

export function mapperStep({ goTo, values, previous, next, setFieldValue }) {
	return {
		id: "mapper",
		label: "Mapper",
		render: () => {
			const headerOptions =
				values.headers?.map((header) => ({
					value: header.column,
					label: header.column,
				})) || [];
			return (
				<div>
					<StepTitle>Mapping required fields for CSV invitations</StepTitle>
					{[
						{ key: "externalUserCode", label: "External User Code" },
						{ key: "siteId", label: "Site ID" },
						{ key: "groupName", label: "Group Name" },
					].map((column) => {
						return (
							<div
								key={column.key}
								style={{ display: "flex", marginBottom: "10px" }}
							>
								<StepText
									style={{
										marginTop: "5px",
										marginRight: "15px",
										width: "200px",
										fontWeight: "normal",
										fontSize: "18px",
									}}
								>
									{column.label}
								</StepText>
								<Dropdown
									style={{ width: "200px" }}
									options={headerOptions}
									value={headerOptions?.find(
										(option) =>
											option.value === values.mapper?.[column.key]?.column
									)}
									onChange={(value) => {
										setFieldValue(`mapper.${column.key}.column`, value.value);
									}}
								/>
							</div>
						);
					})}
					<StepText>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								marginBottom: "20px",
								marginTop: "50px",
							}}
						>
							Value mapping
							{!values.valueMappings?.length && (
								<Icon>
									<PlusCircle
										onClick={() => {
											setFieldValue("valueMappings", [
												{ column: "", from: "", to: "" },
											]);
										}}
										color={colours.blue}
									/>
								</Icon>
							)}
						</div>
					</StepText>
					{values.valueMappings?.length ? (
						<>
							<div
								style={{ display: "flex", marginBottom: "10px", gap: "20px" }}
							>
								<StepText
									style={{
										fontSize: "18px",
										fontWeight: "normal",
										width: "200px",
									}}
								>
									Column
								</StepText>
								<StepText
									style={{
										fontSize: "18px",
										fontWeight: "normal",
										width: "170px",
									}}
								>
									From
								</StepText>
								<StepText
									style={{
										fontSize: "18px",
										fontWeight: "normal",
										width: "200px",
									}}
								>
									To
								</StepText>
							</div>
							<Repeater
								minusMode="all"
								canRemoveFirst={true}
								items={values.valueMappings}
								add={() => {
									setFieldValue("valueMappings", [
										...values.valueMappings,
										{ column: "", from: "", to: "" },
									]);
								}}
								subtract={(index) => {
									const items = [...values.valueMappings];
									if (index > -1) items.splice(index, 1);
									setFieldValue("valueMappings", items);
								}}
								template={(item, index) => {
									const isSiteIdColumn =
										values.mapper?.siteId?.column === item?.column;
									return (
										<div style={{ display: "flex", gap: "20px" }}>
											<Dropdown
												style={{ width: "200px" }}
												options={headerOptions}
												value={headerOptions.find(
													(option) => option.value === item?.column
												)}
												onChange={(value) => {
													setFieldValue(
														`valueMappings.${index}.column`,
														value.value
													);
												}}
											/>
											<InputWithIcon
												value={item?.from}
												onFormat={(value) => {
													setFieldValue(`valueMappings.${index}.from`, value);
												}}
											/>
											{isSiteIdColumn ? (
												<Dropdown
													style={{ width: "200px" }}
													options={values.selectedSites}
													value={values.selectedSites?.find(
														(site) => site.value?.toString() === item?.to
													)}
													onChange={(value) => {
														setFieldValue(
															`valueMappings.${index}.to`,
															value.value?.toString()
														);
													}}
												/>
											) : (
												<InputWithIcon
													style={{ width: "200px" }}
													value={item?.to}
													onFormat={(value) => {
														setFieldValue(`valueMappings.${index}.to`, value);
													}}
												/>
											)}
										</div>
									);
								}}
							/>
						</>
					) : null}
				</div>
			);
		},
		footer: () => {
			return (
				<WizardNavigation
					leftItems={
						values.editFromSummary
							? []
							: [
									<Button key="submit" color="blue" onClick={previous}>
										Back
									</Button>,
							  ]
					}
					rightItems={[
						<Button
							key="submit"
							color="blue"
							onClick={() => {
								if (values.editFromSummary) {
									goTo("summary");
								} else {
									next();
								}
							}}
						>
							{values.editFromSummary ? "Review" : "Next"}
						</Button>,
					]}
				/>
			);
		},
	};
}

export function intervalStep({
	isSubmitting,
	keyStrokeHandler,
	goTo,
	values,
	next,
	previous,
	handleChange,
}) {
	return {
		id: "interval",
		label: "Interval",
		render: () => {
			return (
				<div>
					<StepTitle>
						How often should we poll this data for? (in minutes)
					</StepTitle>
					<Input
						type="number"
						min={2}
						name="interval"
						value={values.interval}
						onChange={handleChange}
					/>
				</div>
			);
		},
		footer: () => {
			return (
				<WizardNavigation
					leftItems={
						values.editFromSummary
							? []
							: [
									<Button key="previous" onClick={previous} color="blue">
										Back
									</Button>,
							  ]
					}
					rightItems={[
						<Button
							key="submit"
							color="blue"
							onClick={() => {
								if (values.editFromSummary) {
									goTo("summary");
								} else {
									next();
								}
							}}
							disabled={isSubmitting}
							keyStrokeHandler={keyStrokeHandler}
						>
							{values.editFromSummary ? "Review" : "Next"}
						</Button>,
					]}
				/>
			);
		},
	};
}

export function expiryStep({
	isSubmitting,
	keyStrokeHandler,
	goTo,
	values,
	next,
	previous,
	handleChange,
}) {
	return {
		id: "expiry",
		label: "Expiry Period",
		render: () => {
			return (
				<div>
					<StepTitle>How long should the exit plate reads be cached?</StepTitle>
					<Input
						type="number"
						min={24}
						name="expiry"
						value={values.expiry || ""}
						onChange={handleChange}
					/>
				</div>
			);
		},
		footer: () => {
			return (
				<WizardNavigation
					leftItems={
						values.editFromSummary
							? []
							: [
									<Button key="previous" onClick={previous} color="blue">
										Back
									</Button>,
							  ]
					}
					rightItems={[
						<Button
							key="submit"
							color="blue"
							onClick={() => {
								if (values.editFromSummary) {
									goTo("summary");
								} else {
									next();
								}
							}}
							disabled={isSubmitting}
							keyStrokeHandler={keyStrokeHandler}
						>
							{values.editFromSummary ? "Review" : "Next"}
						</Button>,
					]}
				/>
			);
		},
	};
}

export const synchronousStep = ({
	values,
	setFieldValue,
	next,
	previous,
	goTo,
	keyStrokeHandler,
}) => {
	return {
		id: "isSynchronous",
		label: "Execution",
		render: () => (
			<div>
				<StepTitle>{"Can this integration be queued?"}</StepTitle>
				<InfoBox
					style={{
						marginBottom: 16,
					}}
					text={
						"An integration can be queued if it doesn't require immediate execution, and does not include large resources such as images in the request body."
					}
				/>
				<MultiChoiceButton
					selected={!values.isSynchronous}
					onClick={() => {
						setFieldValue("isSynchronous", false);
					}}
				>
					Yes
				</MultiChoiceButton>
				<MultiChoiceButton
					selected={values.isSynchronous}
					onClick={() => {
						setFieldValue("isSynchronous", true);
					}}
				>
					No
				</MultiChoiceButton>
			</div>
		),
		footer: () => {
			return (
				<WizardNavigation
					leftItems={
						values.editFromSummary
							? []
							: [
									<Button key="submit" color="blue" onClick={previous}>
										Back
									</Button>,
							  ]
					}
					rightItems={[
						<Button
							key="submit"
							color="blue"
							onClick={() => {
								if (values.editFromSummary) {
									goTo("summary");
								} else {
									next();
								}
							}}
							keyStrokeHandler={keyStrokeHandler}
						>
							{values.editFromSummary ? "Review" : "Next"}
						</Button>,
					]}
				/>
			);
		},
	};
};

export const isEnabledStep = ({
	values,
	setFieldValue,
	next,
	previous,
	goTo,
	keyStrokeHandler,
}) => {
	return {
		id: "isEnabled",
		label: "Status",
		render: () => (
			<div>
				<StepTitle>Should this integration be enabled now?</StepTitle>

				<MultiChoiceButton
					selected={!values.isEnabled}
					onClick={() => {
						setFieldValue("isEnabled", false);
					}}
				>
					No
				</MultiChoiceButton>
				<MultiChoiceButton
					selected={values.isEnabled}
					onClick={() => {
						setFieldValue("isEnabled", true);
					}}
				>
					Yes
				</MultiChoiceButton>
			</div>
		),
		footer: () => {
			return (
				<WizardNavigation
					leftItems={
						values.editFromSummary
							? []
							: [
									<Button key="submit" color="blue" onClick={previous}>
										Back
									</Button>,
							  ]
					}
					rightItems={[
						<Button
							key="submit"
							color="blue"
							onClick={() => {
								if (values.editFromSummary) {
									goTo("summary");
								} else {
									next();
								}
							}}
							keyStrokeHandler={keyStrokeHandler}
						>
							{values.editFromSummary ? "Review" : "Next"}
						</Button>,
					]}
				/>
			);
		},
	};
};

export const loggingEnabledStep = ({
	values,
	setFieldValue,
	next,
	previous,
	goTo,
	keyStrokeHandler,
}) => {
	return {
		id: "loggingEnabled",
		label: "Logging",
		render: () => (
			<div>
				<StepTitle>Do you want logging enabled on this integration?</StepTitle>

				<MultiChoiceButton
					selected={!values.loggingEnabled}
					onClick={() => {
						setFieldValue("loggingEnabled", false);
					}}
				>
					No
				</MultiChoiceButton>
				<MultiChoiceButton
					selected={values.loggingEnabled}
					onClick={() => {
						setFieldValue("loggingEnabled", true);
					}}
				>
					Yes
				</MultiChoiceButton>
			</div>
		),
		footer: () => {
			return (
				<WizardNavigation
					leftItems={
						values.editFromSummary
							? []
							: [
									<Button key="submit" color="blue" onClick={previous}>
										Back
									</Button>,
							  ]
					}
					rightItems={[
						<Button
							key="submit"
							color="blue"
							onClick={() => {
								if (values.editFromSummary) {
									goTo("summary");
								} else {
									next();
								}
							}}
							keyStrokeHandler={keyStrokeHandler}
						>
							{values.editFromSummary ? "Review" : "Next"}
						</Button>,
					]}
				/>
			);
		},
	};
};

export const summaryStep =
	(mode) =>
	({
		close,
		goTo,
		handleSubmit,
		isSubmitting,
		setFieldValue,
		values,
		wizardProps,
	}) => {
		const showCameras = false;

		const extraStepsByType = {
			[INTEGRATION_TYPES.WEBHOOK]: [],
			[INTEGRATION_TYPES.POLLING]: [
				{
					title: "Interval",
					value: `${values.interval} Minute${values.interval === 1 ? "" : "s"}`,
					key: "interval",
					edit: () => {
						setFieldValue("editFromSummary", true);
						goTo("interval");
					},
				},
				{
					title: "Expiry Period",
					value: `${values.expiry} Hour${values.expiry === 1 ? "" : "s"}`,
					key: "expiry",
					edit: () => {
						setFieldValue("editFromSummary", true);
						goTo("expiry");
					},
				},
			],
			[INTEGRATION_TYPES.FILE_TRANSFER]: [
				{
					title: "Directory",
					value: values.directory,
					key: "directory",
					edit: () => {
						setFieldValue("editFromSummary", true);
						goTo("directory");
					},
				},
				{
					title: "Headers",
					value: values.headers?.map((header) => header.column).join(", "),
					key: "headers",
					edit: () => {
						setFieldValue("editFromSummary", true);
						goTo("headers");
					},
				},
				{
					title: "Mapper",
					value: "Click Edit to view details",
					key: "mapper",
					edit: () => {
						setFieldValue("editFromSummary", true);
						goTo("mapper");
					},
				},
			],
		};
		const extraSteps = extraStepsByType[values.integrationType] || [];

		const getContextualItem = (name, errorMessage) => {
			const valueKey = `selected${name}`;
			const selectedValues = values[valueKey];
			const label =
				values[`available${name}`].length === selectedValues.length
					? `All ${name}`
					: selectedValues.map((s) => s.label).join(", ");

			return {
				title: name,
				value: (
					<div style={{ color: errorMessage ? colours.red : undefined }}>
						{errorMessage || label}
					</div>
				),
				key: valueKey,
				edit: () => {
					setFieldValue("editFromSummary", true);
					goTo(`select${name}`);
				},
			};
		};

		const isEditing = mode === "edit";
		const isExporting = mode === "export";
		const isImporting = [
			CREATE_METHOD_KEYS.IMPORT,
			CREATE_METHOD_KEYS.TEMPLATE,
		].includes(values.createMethod);
		const variableLength = values.importContent?.Variables?.length;
		const noSitesSelected = !showCameras && values.selectedSites?.length === 0;

		const importSteps = isImporting
			? [
					{
						title: "Variables",
						value: variableLength
							? `Importing ${variableLength} variable${
									variableLength === 1 ? "" : "s"
							  }`
							: "Nothing to import",
						key: "variables",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("variables");
						},
					},
			  ]
			: [];

		const items = [
			isExporting
				? null
				: {
						title: "Create",
						value: CREATE_METHOD_BY_KEY[values.createMethod],
						key: "createMethod",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("createMethod");
						},
				  },
			{
				title: "Integration Type",
				value: values.integrationType,
				key: "integrationType",
				edit: () => {
					setFieldValue("editFromSummary", true);
					goTo("integrationType");
				},
			},
			{
				title: "Event Type",
				value: EVENT_TYPES_TRANSLATIONS[values.eventType],
				key: "eventType",
				edit: () => {
					setFieldValue("editFromSummary", true);
					goTo("eventType");
				},
			},
			wizardProps.context === "Client"
				? showCameras
					? getContextualItem("Cameras")
					: getContextualItem(
							"Sites",
							noSitesSelected ? "Please select at least one site" : null
					  )
				: null,
			{
				title: `Request${isExporting ? "s" : ""}`,
				value: (
					<>
						<Label
							style={{ marginRight: "10px" }}
							color={COLORS_BY_REQUEST_METHOD[values.request?.method]}
						>
							{values.request?.method}
						</Label>
						{values.request?.label}
					</>
				),
				key: "request",
				edit: () => {
					setFieldValue("editFromSummary", true);
					goTo("request");
				},
			},
			...importSteps,
			...extraSteps,
			isExporting
				? null
				: {
						title: "Synchronous",
						value: values.isSynchronous ? "Yes" : "No",
						key: "isSynchronous",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("isSynchronous");
						},
				  },
			isExporting
				? null
				: {
						title: "Status",
						value: values.isEnabled ? (
							<Label color="green">Enabled</Label>
						) : (
							<Label color="yellow">Disabled</Label>
						),
						key: "state",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("isEnabled");
						},
				  },
			isExporting
				? null
				: {
						title: "Logging",
						value: values.loggingEnabled ? (
							<Label color="green">Enabled</Label>
						) : (
							<Label color="yellow">Disabled</Label>
						),
						key: "loggingEnabled",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("loggingEnabled");
						},
				  },
			isExporting
				? {
						title: "Export",
						value: (
							<>
								{[
									{ value: "file", label: "As File" },
									{ value: "template", label: "As Template" },
								].map((choice) => (
									<MultiChoiceButton
										key={choice.value}
										selected={values.exportAs === choice.value}
										onClick={() => {
											setFieldValue("exportAs", choice.value);
										}}
									>
										<div>{choice.label}</div>
									</MultiChoiceButton>
								))}
							</>
						),
						key: "export",
				  }
				: null,
			isExporting && values.exportAs === "template"
				? {
						title: "Template Name",
						value: (
							<>
								<Input
									value={values.templateName || ""}
									onChange={(event) => {
										setFieldValue("templateName", event.target.value);
									}}
								/>
							</>
						),
						key: "templateName",
				  }
				: null,
		];

		return {
			id: "summary",
			label: "Summary",
			render: () => (
				<div>
					<StepTitle>{isExporting ? "Export" : ""} Summary</StepTitle>
					<SummaryTable
						readOnly={isExporting}
						valueStyle={{ whiteSpace: "none" }}
						items={items.filter((i) => i)}
					/>
				</div>
			),
			footer: () => {
				return (
					<WizardNavigation
						leftItems={[
							<Button key="previous" onClick={close} color="blue">
								Cancel
							</Button>,
						]}
						rightItems={[
							<Button
								key="submit"
								color="green"
								onClick={handleSubmit}
								disabled={
									isSubmitting ||
									(noSitesSelected &&
										wizardProps.context === INTEGRATION_CONTEXT.CLIENT)
								}
							>
								{isExporting ? "Export" : isEditing ? "Update" : "Create"}
							</Button>,
						]}
					/>
				);
			},
		};
	};

export const viewCacheStep =
	(cachedData) =>
	({ close }) => {
		const data = (cachedData || []).map((d) => JSON.parse(d));

		const columns = data.length
			? Object.keys(data[0]).map((k) => {
					return {
						id: k,
						Header: k,
						accessor: (d) => d[k],
					};
			  })
			: [
					{
						id: "cache",
						Header: "Cache",
						accessor: () => "No results found",
					},
			  ];
		return {
			id: "viewCache",
			label: "Cache",
			render: () => (
				<div>
					<StepTitle>Recent polling events</StepTitle>
					<TableLayout data={data} columns={columns} />
				</div>
			),
			footer: () => {
				return (
					<WizardNavigation
						leftItems={[
							<Button key="submit" color="blue" onClick={close}>
								Close
							</Button>,
						]}
					/>
				);
			},
		};
	};
