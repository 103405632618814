import Dropdown from "../../../layout/Dropdown";
import InputWithIcon from "../../../layout/InputWithIcon";
import React from "react";
import { StepTitle } from "../../WizardLayout";
import Toggle from "../../../layout/Toggle";
import { money } from "../../../../helpers/format";
import styled from "styled-components";

const Separator = styled.div`
	display: inline-block;
	margin: 8px 4px 0;
	padding: 0 10px 0 10px;
`;

const Wrapper = styled.div`
	display: flex;
`;

export default ({ values, setFieldValue, goTo, wizardProps }) => {
	const isEventSeriesRate =
		values.isEventSeriesRate || values?.type?.value === "EventSeries";

	const options = [...wizardProps.rateScales];
	if (isEventSeriesRate) {
		options.push({ value: -1, label: "Entire Event" });
	}

	const rateForm = (
		<>
			<StepTitle>How much will it cost to park on this rate?</StepTitle>
			<Wrapper>
				<InputWithIcon
					allowNull={true}
					value={values.feePerUnit}
					onFormat={(value) => {
						setFieldValue("feePerUnit", value);
					}}
					icon="DollarSign"
					align="left"
				/>{" "}
				<Separator>per</Separator>
				<div style={{ width: "200px" }}>
					<Dropdown
						options={[
							...wizardProps.rateScales,
							{ value: -1, label: "Exact Event Duration" },
						]}
						value={values.unitStaggeringScale}
						onChange={(unitStaggeringScale) => {
							setFieldValue("unitStaggeringScale", unitStaggeringScale);
						}}
					/>
				</div>
				<Separator>max</Separator>
				<InputWithIcon
					allowNull
					value={values.maxFee}
					onFormat={(value) => {
						setFieldValue("maxFee", value || null);
					}}
					icon="DollarSign"
					align="left"
				/>
			</Wrapper>

			{values.unitStaggeringScale?.value === -1 && (
				<p>
					An exact event duration rate is a fixed charge applied for the
					duration of an event, regardless of the exact duration of the service
					used within that period. A consumer qualifies for this rate if they
					start their parking session within the rate start and end time. If the
					consumer crosses into the timeframe of a new rate, they will be
					charged the new rate and the event rate will no longer apply.
				</p>
			)}

			{values.unitStaggeringScale?.value === 1440 && (
				<>
					<br />
					<br />
					<Wrapper>
						<Toggle
							label="Charge rate once per day?"
							onChange={(value) => setFieldValue("appliedCrossSession", value)}
							checked={values.appliedCrossSession}
						/>
					</Wrapper>
					<p>
						Note: If this is turned on, a user will only be charged this fee a
						maximum of once per calendar day. This means that only the first
						session they have with this rate at the site will be charged until
						the following day when it will be applied again.
					</p>
				</>
			)}
		</>
	);

	return {
		id: "standard",
		summary: [
			{
				label: "Rate",
				value: `${money(
					values.feePerUnit
				)} per ${values.unitStaggeringScale?.label?.toLowerCase()}`,
				id: "rate",
				edit: () => {
					setFieldValue("editFromSummary", true);
					setFieldValue("configuration", "standard");
					goTo("configuration");
				},
			},
			{
				label: "Maximum Fee",
				value: values.maxFee ? money(values.maxFee) : "No limit",
				id: "max",
				edit: () => {
					setFieldValue("editFromSummary", true);
					setFieldValue("configuration", "standard");
					goTo("configuration");
				},
			},
			values.unitStaggeringScale.value === 1440
				? {
						label: "Charged Once",
						value: values.appliedCrossSession ? "Yes" : "No",
						id: "appliedCrossSession",
						edit: () => {
							setFieldValue("editFromSummary", true);
							setFieldValue("configuration", "standard");
							goTo("configuration");
						},
				  }
				: null,
		].filter((e) => e),
		render: () => rateForm,
		isValid: true,
	};
};
