import React, { useEffect, useMemo, useState } from "react";
import { filter, intersection, isNil, map } from "lodash";
import {
	useFetchData,
	useMutateData,
	usePermissions,
	useQueryData,
	useSearchFilter,
} from "../../hooks";
import Alert from "react-s-alert";
import Button from "../../components/layout/Button";
import Card from "../../components/layout/Card";
import DropdownMenu from "../../components/layout/DropdownMenu";
import FlexWrapper from "../../components/layout/FlexWrapper";
import { MoreHorizontal } from "react-feather";
import ParkingSessionFeePreview from "../../components/report/ParkingSessionFeePreview";
import ReportWrapper from "../../components/report/ReportWrapper";
import SessionWizard from "../../components/wizards/session-wizard/SessionWizard";
import StatCard from "../../components/layout/StatCard";
import TabSelector from "../../components/layout/TabSelector";
import { booleanValueFormatter } from "../../components/layout/TableLayout";
import { format } from "../../helpers";
import gql from "graphql-tag";
import moment from "moment";
import * as installationApi from "../../api/installation";
import ReportPicker from "../../components/report/ReportPicker";

const columns = (props) => [
	{
		id: "IsActive",
		Header: "Active",
		accessor: (d) => d.IsActive,
		Cell: (cellProps) => booleanValueFormatter(cellProps, true),
		width: 80,
	},
	{
		id: "Booking",
		Header: "Booking",
		accessor: (d) => d.UserLeaseID,
		Cell: (cellProps) => booleanValueFormatter(cellProps, true),
		width: 80,
	},
	{
		id: "Source",
		Header: "Source",
		accessor: (d) => d.Source,
		highlightCell: (cellProps) => cellProps.original.Source,
		width: 100,
	},
	{
		id: "User",
		Header: "User",
		accessor: (d) => d.UserName,
		highlightCell: (cellProps) => cellProps.original.UserName,
		className: "bold",
	},
	{
		id: "NumberplateValue",
		Header: "Plate",
		accessor: (d) => d.NumberplateValue || "",
		highlightCell: (cellProps) => cellProps.original.NumberplateValue || "",
		width: 120,
	},
	{
		id: "SiteName",
		Header: "Site",
		accessor: (d) => d.Site?.Name ?? "",
		highlightCell: (cellProps) => cellProps.original.Site?.Name ?? "",
	},
	{
		id: "Groups",
		Header: "Groups",
		accessor: (d) => d.displayOrganizationAccessGroups,
		highlightCell: (cellProps) =>
			cellProps.original.displayOrganizationAccessGroups,
	},
	{
		id: "StartDate",
		Header: "Start Time",
		accessor: (d) => d.entryUnixTimestamp,
		highlightCell: (cellProps) =>
			cellProps.original.EntryTimestamp || "Unknown",
	},
	{
		id: "EndDate",
		Header: "End Time",
		accessor: ({ exitUnixTimestamp }) => exitUnixTimestamp,
		highlightCell: ({ original: { IsActive, ExitTimestamp } }) => {
			if (!ExitTimestamp) {
				if (IsActive) {
					return "";
				}
				return "Unknown";
			}

			return ExitTimestamp;
		},
	},
	{
		id: "ExpiresAt",
		Header: "Expiry Time",
		accessor: (d) => d.expiresAtUnixTimestamp,
		highlightCell: (cellProps) => cellProps.original.ExpiresAt || "",
	},
	{
		id: "Duration",
		Header: "Duration",
		accessor: (d) => d.Duration,
		Cell: (cellProps) => cellProps.original.DurationFormatted,
	},
	{
		id: "Fee",
		Header: "Fee",
		accessor: (d) => d.Fee,
		Cell: (cellProps) => {
			if (!cellProps.original.IsActive || cellProps.original.IsExternal) {
				return format.money(cellProps.original.Fee);
			} else {
				return (
					<ParkingSessionFeePreview
						key={cellProps.original.ParkingSessionID}
						parkingSessionId={cellProps.original.ParkingSessionID}
						siteId={cellProps.original.Site.SiteID}
						fee={cellProps.original.Fee !== -1 ? cellProps.original.Fee : null}
						onChange={(fee) => {
							props.setState((_state) => ({
								..._state,
								sessionPreviewFees: {
									..._state.sessionPreviewFees,
									[cellProps.original.ParkingSessionID]: fee,
								},
							}));
						}}
					/>
				);
			}
		},
		className: "bold",
	},
];

const taskColumn = (props) => ({
	id: "tasks",
	Header: "",
	accessor: null,
	Cell: (cellProps) =>
		cellProps.original.IsActive &&
		!cellProps.original.IsExternal &&
		props.canManageSessions &&
		// temporarily disable end session button for web pay sessions till we decide how to handle it
		!cellProps.original.IsWebPaySession ? (
			<DropdownMenu
				triggerContent={<MoreHorizontal size={24} />}
				items={[
					<div
						key="end-session"
						onClick={() =>
							props.openSessionWizard(cellProps.original, "end-session")
						}
					>
						End Session
					</div>,
				]}
			/>
		) : null,
	resizable: false,
	width: 50,
	sortable: false,
});

const defaultSorting = [
	{
		id: "StartDate",
		desc: true,
	},
];

function useReportFilter(sessions, search, selectedGroupIds) {
	const searchedSessions = useSearchFilter(sessions || [], search, [
		"UserName",
		"NumberplateValue",
		"Site.Name",
		"EntryTimestamp",
		"ExitTimestamp",
		"Fee",
	]);

	return useMemo(() => {
		if (!selectedGroupIds || !searchedSessions) {
			return searchedSessions;
		}

		return searchedSessions.filter((session) => {
			return (
				session.CachedOrganizationAccessGroupIDs?.some((id) =>
					selectedGroupIds.includes(id)
				) ||
				(!session.CachedOrganizationAccessGroupIDs?.length &&
					selectedGroupIds.includes(null))
			);
		});
	}, [searchedSessions, selectedGroupIds]);
}

function useGetOrganizationGroups(organizationId, siteIds) {
	const { data: organizationAccessGroups, isLoading } = useFetchData(
		[],
		installationApi.getOrganizationAccessGroupsWithSites,
		[organizationId],
		[organizationId]
	);

	return useMemo(() => {
		const filteredAccessGroups = organizationAccessGroups?.filter((oag) =>
			oag.sites.some((s) => siteIds.includes(s.SiteID))
		);

		filteredAccessGroups.push({
			Name: "No group",
			OrganizationAccessGroupID: null,
		});

		return { organizationAccessGroups: filteredAccessGroups, isLoading };
	}, [organizationAccessGroups, siteIds, isLoading]);
}

export default function TransientContainer(props) {
	const [state, setState] = useState({
		initialLoad: true,
		options: {
			sites: [],
			"start-date-time": null,
			"end-date-time": null,
			startDateTime: null,
			endDateTime: null,
		},
		search: "",
		sessionFilter: "active",
		sessionAdminWizardOpen: false,
		sessionPreviewFees: {},
		selectedGroupIds: null,
	});

	const organizationId = props.selectedOrganization
		? props.selectedOrganization.OrganizationID
		: null;

	const canManageSessions = usePermissions(null, "SessionAdmin", true);

	async function updateOptions({ search, ...options }) {
		setState((_state) => ({
			..._state,
			initialLoad: false,
			search,
			options: {
				...options,
				startDateTime: moment(options["start-date-time"])
					.startOf("day")
					.format(),
				endDateTime: moment(options["end-date-time"]).endOf("day").format(),
			},
		}));
	}

	const bulkDownloadReport = useMutateData(gql`
		mutation ($args: BulkDownloadReportInput!) {
			bulkDownloadTransientReport(args: $args)
		}
	`);

	const skipQuery = !organizationId || !state.options.sites.length;

	const { organizationAccessGroups, isLoadingGroups } =
		useGetOrganizationGroups(organizationId, state.options.sites);

	useEffect(() => {
		if (!organizationAccessGroups?.length) {
			return;
		}

		const groupIds = organizationAccessGroups.map(
			(g) => g.OrganizationAccessGroupID
		);

		setState((_state) => {
			// when selected sites changes, don't re-add new groups as being selected.

			const noGroupOnly =
				_state.selectedGroupIds?.length === 1 && !_state.selectedGroupIds[0];
			return {
				..._state,
				selectedGroupIds:
					_state.selectedGroupIds && !noGroupOnly
						? intersection(_state.selectedGroupIds, groupIds)
						: groupIds,
			};
		});
	}, [organizationAccessGroups]);

	const {
		data: { getSessionsForOrganization: sessionData },
		isLoading: isLoadingSessions,
	} = useQueryData(
		gql`
			query (
				$organizationId: Int!
				$siteIds: [Int!]!
				$startDateTime: Timestamp
				$endDateTime: Timestamp
				$searchTokens: String
			) {
				getSessionsForOrganization(
					organizationId: $organizationId
					siteIds: $siteIds
					startDateTime: $startDateTime
					endDateTime: $endDateTime
					searchTokens: $searchTokens
				) {
					sessions {
						ParkingSessionID
						IsActive
						IsWebPaySession
						UserName
						UserID
						NumberplateValue
						Site {
							SiteID
							Name
						}
						SiteTimezone
						UserLeaseID
						Source
						EntryTimestamp
						ExitTimestamp
						ExpiresAt
						Duration
						ExpiryDuration
						Fee
						DurationFormatted
						IsExternal
						CachedOrganizationAccessGroupIDs
					}
					currentSessionCount
					sessionCount
					totalChargedAmount
				}
			}
		`,
		{
			organizationId,
			siteIds: state.options.sites,
			startDateTime: state.options.startDateTime,
			endDateTime: state.options.endDateTime,
			searchTokens: null,
		},
		skipQuery
	);

	let filteredSessions = useReportFilter(
		sessionData?.sessions,
		state.search,
		state.selectedGroupIds
	);

	filteredSessions = map(filteredSessions, (session) => {
		let fee = state.sessionPreviewFees[session.ParkingSessionID] ?? session.Fee;

		if (!session.IsActive && fee === null) {
			fee = 0;
		}

		fee = !isNaN(fee) && !isNil(fee) ? parseFloat(fee) : -1;

		const timestampFormat = "hh:mm A on DD MMM YYYY";

		return {
			...session,
			Fee: fee,
			SiteID: session.Site?.SiteID,
			entryUnixTimestamp: moment(
				session.EntryTimestamp,
				timestampFormat
			).unix(),
			exitUnixTimestamp: moment(session.ExitTimestamp, timestampFormat).unix(),
			expiresAtUnixTimestamp: moment(session.ExpiresAt, timestampFormat).unix(),
			User: session.UserName,
			displayOrganizationAccessGroups:
				session.CachedOrganizationAccessGroupIDs?.map(
					(id) =>
						organizationAccessGroups?.find(
							(oag) => oag.OrganizationAccessGroupID === id
						)?.Name ?? "(deleted)"
				)?.join(", ") ?? "",
		};
	});

	filteredSessions = filteredSessions.filter((e) => {
		if (state.sessionFilter === "all") return true;
		if (state.sessionFilter === "active") return e.IsActive;
		if (state.sessionFilter === "closed") return !e.IsActive;
	});

	function openSessionWizard(session, mode) {
		setState((_state) => ({
			..._state,
			sessionAdminWizardOpen: true,
			sessionAdminWizardSession: session,
			sessionAdminWizardMode: mode,
		}));
	}

	async function onSessionFilterChange(sessionFilter) {
		setState((_state) => ({ ..._state, sessionFilter }));
	}

	if (state.sessionAdminWizardOpen) {
		return (
			<SessionWizard
				close={() => {
					setState((_state) => ({ ..._state, sessionAdminWizardOpen: false }));
				}}
				mode={state.sessionAdminWizardMode}
				session={state.sessionAdminWizardSession}
				sites={filter(
					props.availableSites,
					(site) =>
						site.HasCasualParking &&
						!site.HasSessionlessAccess &&
						site.SiteType === "Gated"
				)}
			/>
		);
	}

	const columnProps = {
		searchFilter: state.searchFilter,
		data: sessionData,
		setState: setState,
		openSessionWizard: openSessionWizard,
		canManageSessions: canManageSessions,
	};
	const baseColumns = columns(columnProps);

	return (
		<div>
			<ReportWrapper
				{...props}
				title="Transient Parking"
				data={filteredSessions}
				availableSites={filter(
					props.availableSites,
					(site) =>
						((site.HasCasualParking || site.HasLeaseParking) &&
							!site.HasSessionlessAccess) ||
						(site.SecureParkingExternalID && site.SiteType === "Gated")
				)}
				columns={[
					...baseColumns,
					...(filteredSessions.customColumns?.map((column) => ({
						id: column,
						Header: column,
						accessor: (d) => d[column],
					})) || []),
					taskColumn(columnProps),
				]}
				leftActions={
					<ReportPicker
						style={{ marginLeft: 16 }}
						name={"groups"}
						options={
							organizationAccessGroups?.map((g) => ({
								value: g.OrganizationAccessGroupID,
								label: g.Name,
							})) ?? []
						}
						selected={state.selectedGroupIds ?? []}
						onSelectedChanged={(o) =>
							setState((_state) => ({ ..._state, selectedGroupIds: o }))
						}
					/>
				}
				rightActions={
					canManageSessions ? (
						<Button
							color="blue"
							onClick={() => openSessionWizard(null, "start-session")}
						>
							Start Session
						</Button>
					) : null
				}
				defaultSorted={defaultSorting}
				updateOptions={updateOptions}
				loading={isLoadingSessions || isLoadingGroups}
				onBulkDownloadClicked={async () => {
					try {
						await bulkDownloadReport({
							variables: {
								args: {
									organizationId: props.selectedOrganization?.OrganizationID,
									siteIds: state.options.sites,
									startDateTime: state.options.startDateTime,
									endDateTime: state.options.endDateTime,
									groupIds: state.selectedGroupIds ?? [],
								},
							},
						});

						Alert.success("The report will be sent to your email shortly");
					} catch (error) {
						Alert.error("Something went wrong please try again");
					}
				}}
				stats={
					<div>
						<FlexWrapper style={{ marginBottom: -16 }}>
							<Card>
								<StatCard
									value={sessionData?.currentSessionCount || 0}
									title="Current Inugo Sessions"
									size="medium"
								/>
							</Card>
							<Card>
								<StatCard
									value={sessionData?.sessionCount || 0}
									title="Total Sessions"
									subTitle="Within Selected Dates"
									size="medium"
								/>
							</Card>
							<Card>
								<StatCard
									value={format.money(sessionData?.totalChargedAmount)}
									title="Total Charged"
									subTitle="Within Selected Dates"
									size="medium"
								/>
							</Card>
						</FlexWrapper>
						<div style={{ margin: 32 }}>
							<TabSelector
								items={[
									{ value: "all", label: "All" },
									{ value: "active", label: "Active" },
									{ value: "closed", label: "Closed" },
								]}
								value={state.sessionFilter}
								onChange={onSessionFilterChange}
							/>
						</div>
					</div>
				}
			/>
		</div>
	);
}
