import "typeface-source-sans-pro";
import "normalize.css";
import "./styles/airbnb_fixed.css"; // temporary fix due to syntax error in original file
import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";
import "react-table-legacy/react-table.css";
import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/stackslide.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-phone-input-2/lib/style.css";
import "react-image-lightbox/style.css";
import "react-image-crop/dist/ReactCrop.css";

import {
	ApolloClient,
	ApolloLink,
	ApolloProvider,
	createHttpLink,
} from "@apollo/client";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { baseGraphqlPath, getSessionKey } from "./api";
import AppWrapper from "./containers/AppWrapper";
import AuthContainer from "./containers/auth/AuthContainer";
import { InMemoryCache } from "@apollo/client/cache";
import React from "react";
import ReactDOM from "react-dom";
import RootContainer from "./containers/RootContainer";
import { createUploadLink } from "apollo-upload-client";
import en from "./languages/en";
import { initReactI18next } from "react-i18next";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { use } from "i18next";

if (module.hot) {
	module.hot.accept();
}

use(initReactI18next).init({
	fallbackLng: "en",
	interpolation: { escapeValue: false },
	resources: {
		en: { translation: en },
	},
});

const client = new ApolloClient({
	link: ApolloLink.from([
		new ApolloLink((operation, forward) => {
			const context = operation.getContext();
			if (!getSessionKey() && context.auth) {
				window.location.href = "/login";
				return;
			}

			return forward(operation);
		}),
		onError(({ graphQLErrors, networkError }) => {
			if (graphQLErrors) {
				graphQLErrors.map(({ message, locations, path }) => {
					// eslint-disable-next-line no-console
					console.error(
						`[GraphQL]: Message: ${message}, Location: ${locations}, Path: ${path}`
					);

					if (message === "GQL: not authenticated") {
						window.localStorage.removeItem("sessionKey");
						window.location.href = "/login";
					} else if (message === "GQL: permission denied") {
						window.location.href = "/no-access";
					}
				});
			}

			if (networkError) {
				// eslint-disable-next-line no-console
				console.error(`[Network]: ${networkError}`);
				// window.location.href = "/error"; //TODO: improve this behaviour
			}
		}),
		setContext((_, { variables, headers }) => ({
			variables,
			headers: {
				...headers,
				authorization: getSessionKey(),
			},
		})),
		createUploadLink({
			uri: baseGraphqlPath,
		}),
		createHttpLink({ uri: baseGraphqlPath }),
	]),
	cache: new InMemoryCache({
		typePolicies: {
			Numberplate: { keyFields: ["NumberplateID"] },
			User: { keyFields: ["UserID"] },
		},
	}),
	defaultOptions: {
		watchQuery: { fetchPolicy: "network-only" },
		query: { fetchPolicy: "network-only" },
	},
});

const appEntry = document.getElementById("app");

ReactDOM.render(
	<ApolloProvider client={client}>
		<Router>
			<Route>
				<AppWrapper>
					<Switch>
						<Route
							exact
							path="/login"
							render={(props) => <AuthContainer login={true} {...props} />}
						/>
						<Route
							exact
							path="/forgot-password"
							render={(props) => (
								<AuthContainer forgotPassword={true} {...props} />
							)}
						/>
						<Route
							exact
							path="/reset-password"
							render={(props) => (
								<AuthContainer resetPassword={true} {...props} />
							)}
						/>
						<Route
							exact
							path="/register"
							render={(props) => <AuthContainer register={true} {...props} />}
						/>
						<Route
							exact
							path="/no-access"
							render={(props) => <AuthContainer noAccess={true} {...props} />}
						/>
						<Route
							exact
							path="/error"
							render={(props) => <AuthContainer error={true} {...props} />}
						/>
						<Route path="/" component={RootContainer} />
					</Switch>
				</AppWrapper>
			</Route>
		</Router>
	</ApolloProvider>,
	appEntry
);
