import * as Yup from "yup";
import * as installationApi from "../../..//api/installation";
import * as steps from "./steps";
import {
	DAYS,
	RATE_SCALES,
	RATE_TYPES,
	VARIED_RATE_DURATIONS,
	VARIED_RATE_OFFSET_SCALES,
} from "../../../helpers/organization";
import Alert from "react-s-alert";
import React, { useState } from "react";
import WizardLayout from "../WizardLayout";
import moment from "moment";
import { money } from "../../../helpers/format";
import { sortBy } from "lodash";
import { useGetAllPromotionsForOrganization } from "../../report/validation/ValidationPromotions";
import { paginatedState } from "../../../helpers/pagination";
import LoadingPlaceholder from "../../report/LoadingPlaceholder";
import { useGetValidationQRCodes } from "../../report/validation/ValidationQRCodes";

const constructWizard = () => {
	return {
		initialStep: 0,
		steps: [steps.testStep],
	};
};

const getDuration = (entryTimestamp, exitTimestamp) => {
	var entryMoment = moment.unix(entryTimestamp);
	var exitMoment = moment.unix(exitTimestamp);

	if (typeof exitTimestamp === "undefined" || exitTimestamp === null) {
		exitMoment = moment();
	}

	var timeDifference = exitMoment.diff(entryMoment);
	if (timeDifference < 0) timeDifference = 0;
	var duration = moment.duration(timeDifference);

	var dateParts = {
		days: Math.floor(timeDifference / (86400 * 1000)),
		hours: duration.hours(),
		minutes: duration.minutes(),
		seconds: duration.seconds(),
	};

	var durationDisplay = "";

	var durationParts = [];

	if (dateParts.days > 0) {
		durationParts.push(dateParts.days + " Days");
	}
	if (dateParts.months > 0 || dateParts.days > 0 || dateParts.hours > 0) {
		durationParts.push(dateParts.hours + " Hours");
	}
	if (
		dateParts.months > 0 ||
		dateParts.days > 0 ||
		dateParts.hours > 0 ||
		dateParts.minutes > 0
	) {
		if (dateParts.minutes === 0) {
			if (dateParts.seconds > 0) {
				durationParts.push("Less than 1 Minute");
			}
		} else {
			durationParts.push(dateParts.minutes + " Minutes");
		}
	}
	if (durationParts.length === 0) {
		durationParts.push("Less than 1 Minute");
	}

	durationDisplay = durationParts.join(", ");
	return durationDisplay;
};

export default function RateTesterWizard(props) {
	const initialPaginatedState = {
		...paginatedState,
		pageSize: 1000,
		sortBy: "Name",
		sortOrder: "asc",
	};
	const [paginate] = useState(initialPaginatedState);

	const skip = !paginate.pageSize || !paginate.sortBy || !paginate.sortOrder;
	const selectedSites = [props.site.SiteID] || [];

	const { promotions, promotionsLoading } = useGetAllPromotionsForOrganization(
		props.organization.OrganizationID,
		selectedSites,
		paginate,
		skip
	);

	const { validationQRCodes, isLoading: isLoadingQRCodes } =
		useGetValidationQRCodes(
			selectedSites,
			{
				pageOffset: paginate.pageOffset,
				pageSize: paginate.pageSize,
				sortBy: "CreatedOn",
				sortOrder: paginate.sortOrder,
				searchTokens: paginate.searchTokens,
				eventType: paginate.eventType,
				sortedVal: paginate.cursor.sortedVal,
			},
			paginate.cursor.cursor,
			skip
		);

	if (promotionsLoading || isLoadingQRCodes) {
		return <LoadingPlaceholder />;
	}

	const wizard = constructWizard();

	const isEventSeriesRate = props?.rate?.RateClass === "EventSeries";

	const _rateScaleOptions = [...RATE_SCALES];
	if (isEventSeriesRate) {
		_rateScaleOptions.push({ value: -1, label: "Entire Event" });
	}

	return (
		<WizardLayout
			//need to override the width of the wizard at a specific step
			//in order to support a varied rate
			//supports 2 levels of steps
			widths={{ tester: "1000px" }}
			close={props.close}
			title={`Rate Tester - ${props.site.Name}`}
			values={[
				{
					name: "entryTime",
					value: moment().startOf("hour").toDate(),
					validator: Yup.date(),
				},
				{
					name: "exitTime",
					value: moment().add(1, "hour").startOf("hour").toDate(),
					validator: Yup.date(),
				},
			]}
			onSubmit={async (values, { setSubmitting, setValues }) => {
				setSubmitting(true);

				try {
					let result = await installationApi.testRate(
						props.organization.OrganizationID,
						props.site.SiteID,
						moment(values.entryTime).format("YYYYMMDDHHmmss"),
						moment(values.exitTime).format("YYYYMMDDHHmmss"),
						(values.groups || []).map((group) => group.value),
						(values.beacons || []).map((beacon) => beacon.value),
						values.promotion ? values.promotion.value : null,
						values.validationQRCode ? values.validationQRCode.value : null,
						values.co2Emission ? values.co2Emission : null
					);

					result.charges = sortBy(result.charges, (e) => e.startDateTime);

					let results = [];

					if (result?.promotionDiscount) {
						results.push({
							Rate: `Promotion Discount ${result?.appliedPromotion?.Name}`,
							Fee: `-${money(result.promotionDiscount)}`,
						});
					}

					for (let charge of result.charges) {
						results.push({
							Rate: charge.rate.name,
							Duration: getDuration(charge.startTimestamp, charge.endTimestamp),
							Start: moment(charge.startDateTime, "YYYYMMDDHHmmss").format(
								"DD MMM YYYY HH:mm:ss"
							),
							End: moment(charge.endDateTime, "YYYYMMDDHHmmss").format(
								"DD MMM YYYY HH:mm:ss"
							),
							Fee: money(charge.fee),
						});

						if (charge.subcharges) {
							for (let subcharge of charge.subcharges) {
								results.push({
									isSubcharge: true,
									Duration: getDuration(
										subcharge.startTimestamp,
										subcharge.endTimestamp
									),
									Start: moment(
										subcharge.startDateTime,
										"YYYYMMDDHHmmss"
									).format("DD MMM YYYY HH:mm:ss"),
									End: moment(subcharge.endDateTime, "YYYYMMDDHHmmss").format(
										"DD MMM YYYY HH:mm:ss"
									),
									Fee: money(subcharge.fee),
								});
							}
						}
					}

					results = results.concat(
						result.appliedValidations.map((validation) => ({
							Rate: `Validation Discount - ${validation.Location}`,
							Fee:
								validation.ValidationType === "Percentage"
									? validation.ValidationValue
									: money(validation.ValidationValue),
						}))
					);

					if (result.hasAdditionalOperatorFee) {
						results.push({
							Rate: "Credit Card Fee",
							Fee: money(result.additionalOperatorFee),
						});
					}

					if (result.hasConvenienceFee) {
						results.push({
							Rate: "Convenience Fee",
							Fee: money(result.convenienceFee),
						});
					}

					results.push({
						Rate: "Total Cost",
						Fee: money(result.finalFee),
					});

					results.push({
						Rate: "Paid Until",
						Duration: moment(result.paidUntilTimestamp, "X")
							.tz(props?.site?.Timezone)
							.format("DD MMM YYYY HH:mm:ss"),
					});

					setValues({
						...values,
						results: results,
						finalFee: money(result.finalFee),
					});
				} catch (error) {
					if (error && error.errors[0].code === "EntryIsAfterExitTime") {
						Alert.error("Entry time cannot be after the exit time.");
					} else {
						Alert.error("Something went wrong. Please try again.");
					}
				}

				setSubmitting(false);
			}}
			steps={wizard.steps}
			initialStep={wizard.initialStep}
			wizardProps={{
				...props,
				rateTypes: RATE_TYPES,
				rateScales: _rateScaleOptions,
				variedRateDurations: VARIED_RATE_DURATIONS,
				variedRateOffsetScales: VARIED_RATE_OFFSET_SCALES,
				days: DAYS,
				promotions: promotions?.promotions.map(({ PromotionCode }) => {
					return {
						value: PromotionCode,
						label: PromotionCode,
					};
				}),
				validationQRCodes: validationQRCodes?.qrCodes.map(
					({ QRCodeID, Location }) => {
						return {
							value: QRCodeID,
							label: Location,
						};
					}
				),
			}}
			constructWizard={constructWizard}
		/>
	);
}
