import * as steps from "./steps";
import React from "react";
import WizardLayout from "../WizardLayout";
import gql from "graphql-tag";
import { useMutateData } from "../../../hooks";
import Alert from "react-s-alert";

export default function TemplateWizard(props) {
	const template = props.template;

	const deleteIntegrationTemplate = useMutateData(gql`
		mutation ($id: Int!) {
			deleteIntegrationTemplate(id: $id)
		}
	`);

	const onSubmit = async () => {
		try {
			if (props.mode === "delete") {
				await deleteIntegrationTemplate({
					variables: {
						id: template.IntegrationTemplateID,
					},
				});
				props.close();
				return;
			}
		} catch (error) {
			Alert.error("Something went wrong.");
		}
		props.close();
	};

	if (props.mode === "delete") {
		return (
			<WizardLayout
				rootTabIndex={null}
				close={props.close}
				title="Delete Template"
				onSubmit={onSubmit}
				values={[]}
				steps={[steps.deleteStep]}
				initialStep={0}
				wizardProps={props}
			/>
		);
	}
}
