import React, { Fragment, useEffect, useState } from "react";
import { StepText, StepTitle } from "../WizardLayout";
import { map, sortBy } from "lodash";
import { useLazyQueryData, useQueryData } from "../../../hooks";
import AlertMessage from "../../layout/AlertMessage";
import Button from "../../layout/Button";
import Dropdown from "../../layout/Dropdown";
import EditableInputField from "../../layout/EditableInputField";
import ImportHardware from "./ImportHardware";
import InstallationLookup from "../InstallationLookup";
import Loader from "../../layout/Loader";
import MultiChoiceButton from "../MultiChoiceButton";
import Slider from "../../layout/Slider";
import SummaryTable from "../SummaryTable";
import TableLayout from "../../layout/TableLayout";
import Toggle from "../../layout/Toggle";
import WizardNavigation from "../WizardNavigation";
import { colours } from "../../../styles";
import { constants } from "../../../helpers";
import gql from "graphql-tag";
import styled from "styled-components";

const Wrapper = styled.div`
	a {
		color: ${colours.blue};
		text-decoration: none;
		transition: 0.2s ease;

		&:hover {
			cursor: pointer;
			opacity: 0.8;
		}
	}
`;

const FieldWrapper = styled.div`
	margin: 32px 0;
`;

const FieldContent = styled.div`
	> * {
		margin: 0;
		max-width: 600px;
		min-height: 32px;
	}
`;

const Label = styled.div`
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 4px;
`;

const SubTitle = styled.div`
	font-size: 15px;
	margin-bottom: 4px;
`;

const Note = styled.span`
	color: ${colours.yellow};
`;

function NodeSites(props) {
	const data = props.node.Sites || [];

	return (
		<Wrapper>
			{data.length > 0 && (
				<TableLayout
					data={data}
					columns={[
						{
							id: "Site",
							Header: "Site",
							accessor: "Name",
							resizable: false,
						},
						{
							id: "Edit",
							Header: "",
							Cell: (d) => (
								<a
									href={`/organization/${d.original.OrganizationID}/site/${d.original.SiteID}/installation`}
								>
									Edit Settings
								</a>
							),
							resizable: false,
						},
					]}
					sortable={false}
				/>
			)}
			{!data.length && <StepText>This gate controller is not in use.</StepText>}
		</Wrapper>
	);
}

function SerialNumberSelector(props) {
	const {
		data: { getAvailableNodes: availableNodes },
	} = useQueryData(
		gql`
			query {
				getAvailableNodes {
					NodeID
					SerialNumber
				}
			}
		`
	);

	return (
		<div>
			<StepTitle>Please choose a gate controller</StepTitle>
			<Dropdown
				options={sortBy(
					map(availableNodes, (node) => ({
						value: node.NodeID,
						label: node.SerialNumber,
					})),
					"label"
				)}
				value={
					props.node
						? { value: props.node.NodeID, label: props.node.SerialNumber }
						: null
				}
				onChange={(node) => {
					props.setFieldValue("node", {
						NodeID: node.value,
						SerialNumber: node.label,
					});
					props.setFieldValue("nodeId", node.value);
					props.setFieldValue("serialNumber", node.label);
				}}
			/>
		</div>
	);
}

export const serialNumberStep = ({
	values,
	setFieldValue,
	goTo,
	wizardProps,
	next,
	keyStrokeHandler,
}) => ({
	id: "serialNumber",
	label: "Serial Number",
	render: () => (
		<SerialNumberSelector
			site={wizardProps.site}
			node={values.node}
			siteId={values.siteId}
			setFieldValue={setFieldValue}
			serialNumber={values.serialNumber}
		/>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button key="cancel" onClick={wizardProps.close} color="blue">
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={() => {
						if (values.editFromSummary) {
							goTo("summary");
						} else {
							next();
						}
					}}
					disabled={!values.nodeId}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.editFromSummary ? "Review" : "Next"}
				</Button>,
			]}
		/>
	),
});

export const nameStep = ({
	values,
	setFieldValue,
	next,
	previous,
	goTo,
	keyStrokeHandler,
}) => ({
	id: "name",
	label: "Name",
	render: () => (
		<div>
			<StepTitle>What is the name of the gate controller?</StepTitle>
			<EditableInputField
				type="text"
				name={"name"}
				value={values.name || ""}
				useLabel={"Name"}
				onChange={(event) => {
					setFieldValue("name", event.target.value);
				}}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button key="back" color="blue" onClick={previous}>
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={() => {
						if (values.editFromSummary) {
							goTo("summary");
						} else {
							next();
						}
					}}
					disabled={!values.name}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.editFromSummary ? "Review" : "Next"}
				</Button>,
			]}
		/>
	),
});

export const accessTypeStep = ({
	previous,
	goTo,
	values,
	setFieldValue,
	next,
	wizardProps,
	keyStrokeHandler,
}) => {
	const lane = wizardProps.lane;
	const currentNode = wizardProps.node;
	const allNodes =
		lane &&
		[lane.Node1, lane.Node2].filter(
			(node) => node && currentNode && node.NodeID !== currentNode.NodeID
		);

	const hasOnlyOneNode = allNodes.length === 1;
	const node = hasOnlyOneNode ? allNodes[0] : null;
	const existingNode = {
		isEntry: node && node.AccessType === constants.ACCESS_TYPE_VALUES.Entry,
		isExit: node && node.AccessType === constants.ACCESS_TYPE_VALUES.Exit,
		isBoth:
			node && node.AccessType === constants.ACCESS_TYPE_VALUES.EntryAndExit,
	};

	const currentValue = {
		isEntry: values.accessType === constants.ACCESS_TYPE_VALUES.Entry,
		isExit: values.accessType === constants.ACCESS_TYPE_VALUES.Exit,
		isBoth: values.accessType === constants.ACCESS_TYPE_VALUES.EntryAndExit,
	};

	//if for some reason there's no node at this point, the access type is valid
	const accessTypeIsValid = node
		? (existingNode.isEntry && currentValue.isExit) ||
		  (existingNode.isExit && currentValue.isEntry) ||
		  existingNode.isBoth ||
		  currentValue.isBoth ||
		  !values.accessType
		: true;

	const adjacent = {
		Entry: "Exit",
		Exit: "Entry",
	};

	return {
		id: "accessType",
		label: "Access Type",
		render: () => (
			<div>
				<StepTitle>Please choose an access type</StepTitle>
				{!accessTypeIsValid && (
					<Note>
						This lane already has a gate kit for {values.accessType} access,
						please choose {node ? adjacent[node.AccessType] : ""} or remove the
						existing gate kit in order to proceed.
					</Note>
				)}
				<MultiChoiceButton
					selected={values.accessType === constants.ACCESS_TYPE_VALUES.Entry}
					onClick={() => {
						setFieldValue("accessType", constants.ACCESS_TYPE_VALUES.Entry);
					}}
				>
					Entry
				</MultiChoiceButton>
				<MultiChoiceButton
					selected={values.accessType === constants.ACCESS_TYPE_VALUES.Exit}
					onClick={() => {
						setFieldValue("accessType", constants.ACCESS_TYPE_VALUES.Exit);
					}}
				>
					Exit
				</MultiChoiceButton>
				{wizardProps.isAdmin && (
					<MultiChoiceButton
						selected={
							values.accessType === constants.ACCESS_TYPE_VALUES.EntryAndExit
						}
						onClick={() => {
							setFieldValue(
								"accessType",
								constants.ACCESS_TYPE_VALUES.EntryAndExit
							);
						}}
					>
						Entry And Exit
					</MultiChoiceButton>
				)}
			</div>
		),
		footer: () => (
			<WizardNavigation
				leftItems={
					!values.editFromSummary && [
						<Button key="previous" onClick={previous} color="blue">
							Back
						</Button>,
					]
				}
				rightItems={[
					<Button
						key="next"
						color="blue"
						onClick={() => {
							if (values.editFromSummary) {
								goTo("summary");
							} else {
								next();
							}
						}}
						disabled={!values.accessType || !accessTypeIsValid}
						keyStrokeHandler={keyStrokeHandler}
					>
						{values.editFromSummary ? "Review" : "Next"}
					</Button>,
				]}
			/>
		),
	};
};

export const boatRampStep = ({
	previous,
	goTo,
	values,
	setFieldValue,
	next,
	keyStrokeHandler,
}) => ({
	id: "gateType",
	label: "Gate type",
	render: () => (
		<div>
			<StepTitle>Is the gate controller a boat ramp?</StepTitle>
			<MultiChoiceButton
				selected={!values.isBoatRamp}
				onClick={() => {
					setFieldValue("isBoatRamp", false);
				}}
			>
				No
			</MultiChoiceButton>
			<MultiChoiceButton
				selected={values.isBoatRamp}
				onClick={() => {
					setFieldValue("isBoatRamp", true);
				}}
			>
				Yes
			</MultiChoiceButton>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button key="previous" onClick={previous} color="blue">
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={() => {
						if (values.editFromSummary) {
							goTo("summary");
						} else {
							next();
						}
					}}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.editFromSummary ? "Review" : "Next"}
				</Button>,
			]}
		/>
	),
});

export const showAccessButtonStep = ({
	previous,
	goTo,
	values,
	setFieldValue,
	next,
	keyStrokeHandler,
}) => ({
	id: "showAccessButton",
	label: "Show Access Button",
	render: () => (
		<div>
			<StepTitle>
				Should the app show an access control button instead of a slider?
			</StepTitle>
			<MultiChoiceButton
				selected={!values.showAccessButton}
				onClick={() => {
					setFieldValue("showAccessButton", false);
				}}
			>
				No
			</MultiChoiceButton>
			<MultiChoiceButton
				selected={values.showAccessButton}
				onClick={() => {
					setFieldValue("showAccessButton", true);
				}}
			>
				Yes
			</MultiChoiceButton>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button key="previous" onClick={previous} color="blue">
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={() => {
						if (values.editFromSummary) {
							goTo("summary");
						} else {
							next();
						}
					}}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.editFromSummary ? "Review" : "Next"}
				</Button>,
			]}
		/>
	),
});

export const communicationMethodStep = ({
	previous,
	goTo,
	values,
	setFieldValue,
	next,
	keyStrokeHandler,
}) => ({
	id: "communicationMethod",
	label: "Communication",
	render: () => (
		<div>
			<StepTitle>Please choose a communication method</StepTitle>
			<MultiChoiceButton
				selected={values.communicationMethod === "OnlineWithFailover"}
				onClick={() => {
					setFieldValue("communicationMethod", "OnlineWithFailover");
				}}
			>
				Online With Failover
			</MultiChoiceButton>
			<MultiChoiceButton
				selected={values.communicationMethod === "OnlineOnly"}
				onClick={() => {
					setFieldValue("communicationMethod", "OnlineOnly");
				}}
			>
				Online Only
			</MultiChoiceButton>
			<MultiChoiceButton
				selected={values.communicationMethod === "BluetoothOnly"}
				onClick={() => {
					setFieldValue("communicationMethod", "BluetoothOnly");
				}}
			>
				Bluetooth Only
			</MultiChoiceButton>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button key="previous" onClick={previous} color="blue">
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={() => {
						if (values.editFromSummary) {
							goTo("summary");
						} else {
							next();
						}
					}}
					disabled={!values.communicationMethod}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.editFromSummary ? "Review" : "Next"}
				</Button>,
			]}
		/>
	),
});

export const loopDetectionStep = ({
	previous,
	goTo,
	values,
	setFieldValue,
	next,
	keyStrokeHandler,
}) => ({
	id: "loopDetection",
	label: "Arming Loop",
	render: () => (
		<div>
			<StepTitle>
				Is the gate controller connected to the arming loop?
			</StepTitle>
			<MultiChoiceButton
				selected={!values.loopDetection}
				onClick={() => {
					setFieldValue("loopDetection", false);
				}}
			>
				No
			</MultiChoiceButton>
			<MultiChoiceButton
				selected={values.loopDetection}
				onClick={() => {
					setFieldValue("loopDetection", true);
				}}
			>
				Yes
			</MultiChoiceButton>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button key="previous" onClick={previous} color="blue">
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={() => {
						if (values.editFromSummary) {
							goTo("summary");
						} else {
							next();
						}
					}}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.editFromSummary ? "Review" : "Next"}
				</Button>,
			]}
		/>
	),
});

export const gateOpenSignalStep = ({
	previous,
	goTo,
	values,
	setFieldValue,
	keyStrokeHandler,
	next,
}) => ({
	id: "gateOpenSignal",
	label: "Gate Up Sensor",
	render: () => (
		<div>
			<StepTitle>
				Is the gate controller connected to the gate up sensor?
			</StepTitle>
			<MultiChoiceButton
				selected={!values.gateOpenSignal}
				onClick={() => {
					setFieldValue("gateOpenSignal", false);
				}}
			>
				No
			</MultiChoiceButton>
			<MultiChoiceButton
				selected={values.gateOpenSignal}
				onClick={() => {
					setFieldValue("gateOpenSignal", true);
				}}
			>
				Yes
			</MultiChoiceButton>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button key="previous" onClick={previous} color="blue">
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={() => {
						if (values.editFromSummary) {
							goTo("summary");
						} else {
							next();
						}
					}}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.editFromSummary ? "Review" : "Next"}
				</Button>,
			]}
		/>
	),
});

export const forceSessionlessAccessStep = ({
	goTo,
	values,
	setFieldValue,
	keyStrokeHandler,
	next,
	previous,
}) => ({
	id: "forceSessionlessAccess",
	label: "Sessionless",
	render: () => (
		<div>
			<StepTitle>Should the gate always provide sessionless access?</StepTitle>
			<MultiChoiceButton
				selected={!values.forceSessionlessAccess}
				onClick={() => {
					setFieldValue("forceSessionlessAccess", false);
					setFieldValue("gateAccessInActiveSession", false);
				}}
			>
				No
			</MultiChoiceButton>
			<MultiChoiceButton
				selected={values.forceSessionlessAccess}
				onClick={() => {
					setFieldValue("forceSessionlessAccess", true);
				}}
			>
				Yes
			</MultiChoiceButton>

			{values.forceSessionlessAccess && (
				<>
					<StepText style={{ marginBottom: "10px" }}>
						At what instance?
					</StepText>
					<MultiChoiceButton
						selected={!values.gateAccessInActiveSession}
						onClick={() => {
							setFieldValue("gateAccessInActiveSession", false);
						}}
					>
						Always
					</MultiChoiceButton>
					<MultiChoiceButton
						selected={values.gateAccessInActiveSession}
						onClick={() => {
							setFieldValue("gateAccessInActiveSession", true);
						}}
					>
						When a User has an active session
					</MultiChoiceButton>
				</>
			)}
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button key="back" color="blue" onClick={previous}>
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={() => {
						if (values.editFromSummary) {
							goTo("summary");
						} else {
							next();
						}
					}}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.editFromSummary ? "Review" : "Next"}
				</Button>,
			]}
		/>
	),
});

export const restrictedAccessStep = ({
	goTo,
	values,
	setFieldValue,
	keyStrokeHandler,
	next,
	previous,
}) => ({
	id: "restrictedAccess",
	label: "Restricted",
	render: () => (
		<div>
			<StepTitle>
				Should access to the gate be restricted to nominated groups?
			</StepTitle>
			<Note>
				Note: The groups that have access to this gate can be managed from the
				Groups page under &apos;Permissions&apos;.
			</Note>
			<MultiChoiceButton
				selected={!values.restrictedAccess}
				onClick={() => {
					setFieldValue("restrictedAccess", false);
				}}
			>
				No
			</MultiChoiceButton>
			<MultiChoiceButton
				selected={values.restrictedAccess}
				onClick={() => {
					setFieldValue("restrictedAccess", true);
				}}
			>
				Yes
			</MultiChoiceButton>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button key="back" color="blue" onClick={previous}>
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={() => {
						if (values.editFromSummary) {
							goTo("summary");
						} else {
							next();
						}
					}}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.editFromSummary ? "Review" : "Next"}
				</Button>,
			]}
		/>
	),
});

const SliderIndicator = styled.div`
	margin-left: 16px;
	font-weight: bold;
	font-size: 18px;
	line-height: 28px;
	height: 32px;
`;

const PowerSettings = ({ isAdmin, isOnline, values, setFieldValue }) =>
	isAdmin && values.isPedestal ? (
		isOnline ? (
			<Fragment>
				<FieldWrapper>
					<Label>Gate Controller Transmission Power</Label>
					<SubTitle>To increase the power strength</SubTitle>
					<FieldContent>
						<div style={{ display: "flex" }}>
							<Slider
								min={0}
								max={7}
								onChange={(value) => {
									setFieldValue(
										"gateTxPower",
										constants.TX_POWER.TO_VALUE[value ? value : 0]
									);
								}}
								value={constants.TX_POWER.TO_DISPLAY[values.gateTxPower]}
							/>
							<SliderIndicator>
								{constants.TX_POWER.TO_DISPLAY[values.gateTxPower]}
							</SliderIndicator>
						</div>
					</FieldContent>
				</FieldWrapper>

				<FieldWrapper>
					<Label>Beacon Transmission Power</Label>
					<SubTitle>To increase the power strength</SubTitle>
					<FieldContent>
						<div style={{ display: "flex" }}>
							<Slider
								min={0}
								max={7}
								onChange={(value) => {
									setFieldValue(
										"txPower",
										constants.TX_POWER.TO_VALUE[value ? value : 0]
									);
								}}
								value={constants.TX_POWER.TO_DISPLAY[values.txPower]}
							/>
							<SliderIndicator>
								{constants.TX_POWER.TO_DISPLAY[values.txPower]}
							</SliderIndicator>
						</div>
					</FieldContent>
				</FieldWrapper>

				<FieldWrapper>
					<Label>Beacon Attenuation Value</Label>
					<SubTitle>To decrease the power strength</SubTitle>
					<FieldContent>
						<div style={{ display: "flex" }}>
							<Slider
								min={0}
								max={30}
								onChange={(value) => {
									setFieldValue("attenuation", value ? value : 0);
								}}
								value={values.attenuation}
							/>
							<SliderIndicator>{values.attenuation}</SliderIndicator>
						</div>
					</FieldContent>
				</FieldWrapper>
			</Fragment>
		) : (
			<AlertMessage
				borderColor="midGrey"
				backgroundColor="midGrey"
				textColor="white"
				top="24px"
				text={`Transmission power and attenuation values can only be updated when the gate is online`}
			/>
		)
	) : null;

export const qrCodeStep = ({
	values,
	setFieldValue,
	next,
	previous,
	goTo,
	errors,
	keyStrokeHandler,
}) => ({
	id: "qrCode",
	label: "QR Code",
	render: () => (
		<div>
			<StepTitle>Please supply a QR Code</StepTitle>
			<EditableInputField
				type="text"
				name={"qrCode"}
				value={values.qrCode || ""}
				useLabel={"QR Code"}
				onChange={(event) => {
					setFieldValue("qrCode", event.target.value);
				}}
				error={errors.qrCode ? errors.qrCode : ""}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button key="back" color="blue" onClick={previous}>
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={() => {
						if (values.editFromSummary) {
							goTo("summary");
						} else {
							next();
						}
					}}
					disabled={!values.name || errors.qrCode}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.editFromSummary ? "Review" : "Next"}
				</Button>,
			]}
		/>
	),
});

export const summaryStep = ({
	goTo,
	handleSubmit,
	isSubmitting,
	setFieldValue,
	values,
	wizardProps,
	keyStrokeHandler,
}) => ({
	id: "summary",
	label: "Summary",
	render: () => {
		const items = [
			{
				title: "Serial Number",
				value: values.serialNumber,
				key: "serialNumber",
			},
			{
				title: "Name",
				value: values.name,
				key: "name",
				edit: () => {
					setFieldValue("editFromSummary", true);
					goTo("name");
				},
			},
			{
				title: "Access Type",
				value: values.accessType.split(/(?=[A-Z])/).join(" "),
				key: "accessType",
				edit: () => {
					setFieldValue("editFromSummary", true);
					goTo("accessType");
				},
			},
			{
				title: "Communication",
				value: values.communicationMethod.split(/(?=[A-Z])/).join(" "),
				key: "communicationMethod",
				edit: () => {
					setFieldValue("editFromSummary", true);
					goTo("communicationMethod");
				},
			},
			{
				title: "Boat Ramp",
				value: values.isBoatRamp ? "Yes" : "No",
				key: "gateType",
				edit: () => {
					setFieldValue("editFromSummary", true);
					goTo("gateType");
				},
			},
			{
				title: "Show Access Button",
				value: values.showAccessButton ? "Yes" : "No",
				key: "showAccessButton",
				edit: () => {
					setFieldValue("editFromSummary", true);
					goTo("showAccessButton");
				},
			},
			{
				title: "Arming Loop",
				value: values.loopDetection ? "Yes" : "No",
				key: "loopDetection",
				edit: () => {
					setFieldValue("editFromSummary", true);
					goTo("loopDetection");
				},
			},
			{
				title: "Gate Up Sensor",
				value: values.gateOpenSignal ? "Yes" : "No",
				key: "gateOpenSignal",
				edit: () => {
					setFieldValue("editFromSummary", true);
					goTo("gateOpenSignal");
				},
			},
			{
				title: "Gate Close Relay",
				value: values.gateCloseRelay ? "Yes" : "No",
				key: "gateCloseRelay",
				edit: () => {
					setFieldValue("editFromSummary", true);
					goTo("gateCloseRelay");
				},
			},
			{
				title: "Sessionless Access",
				value: values.forceSessionlessAccess ? "Yes" : "No",
				key: "forceSessionlessAccess",
				edit: () => {
					setFieldValue("editFromSummary", true);
					goTo("forceSessionlessAccess");
				},
			},
			{
				title: "Restricted Access",
				value: values.restrictedAccess ? "Yes" : "No",
				key: "restrictedAccess",
				edit: () => {
					setFieldValue("editFromSummary", true);
					goTo("restrictedAccess");
				},
			},
			{
				title: "QR Code",
				value: values.qrCode,
				key: "qrCode",
				edit: () => {
					setFieldValue("editFromSummary", true);
					goTo("qrCode");
				},
			},
		];

		if (values.forceSessionlessAccess) {
			items.push({
				title: "Gate Access In Active Session",
				value: values.gateAccessInActiveSession ? "Yes" : "No",
				key: "gateAccessInActiveSession",
				edit: () => {
					setFieldValue("editFromSummary", true);
					goTo("forceSessionlessAccess");
				},
			});
		}

		return (
			<div>
				<StepTitle>Summary</StepTitle>
				<SummaryTable items={items} />
			</div>
		);
	},
	footer: () => (
		<WizardNavigation
			rightItems={[
				<Button
					key="submit"
					color="green"
					onClick={handleSubmit}
					disabled={isSubmitting}
					keyStrokeHandler={keyStrokeHandler}
				>
					{wizardProps.mode === "edit-gate-controller" ? "Update" : "Add"}
				</Button>,
			]}
		/>
	),
});

export const toggleNodeStep = ({
	handleSubmit,
	isSubmitting,
	values,
	wizardProps,
}) => ({
	id: "toggle Node",
	label: "Toggle State",
	render: () => (
		<div>
			<StepText>
				Are you sure you want to {values.stateIntent.toLowerCase()} gate
				controller <b>{values.name || values.serialNumber}</b>?
			</StepText>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color={values.stateIntent === "Enable" ? "green" : "red"}
					onClick={handleSubmit}
					disabled={isSubmitting}
				>
					{values.stateIntent}
				</Button>,
			]}
		/>
	),
});

export const deleteNodeStep = ({
	handleSubmit,
	isSubmitting,
	values,
	wizardProps,
}) => ({
	id: "deleteNode",
	label: "Delete Gate Controller",
	render: () => (
		<div>
			<StepText>
				Are you sure you want to delete{" "}
				{values.name || values.serialNumber ? (
					<Fragment>
						gate controller <b>{values.name || values.serialNumber}</b>
					</Fragment>
				) : (
					"this gate controller"
				)}
				?
			</StepText>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="red"
					onClick={handleSubmit}
					disabled={isSubmitting}
				>
					Delete
				</Button>,
			]}
		/>
	),
});

export const adminRefreshSettingsStep = ({
	handleSubmit,
	isSubmitting,
	values,
	wizardProps,
}) => ({
	id: "adminRefreshSettings",
	label: "Refresh Settings",
	render: () => (
		<div>
			<StepText>
				Are you sure you want to refresh the settings for gate{" "}
				<b>{values.serialNumber || ""}</b>?
			</StepText>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="green"
					onClick={handleSubmit}
					disabled={isSubmitting}
				>
					Refresh
				</Button>,
			]}
		/>
	),
});

export const pingGateStep = ({
	handleSubmit,
	isSubmitting,
	values,
	wizardProps,
}) => ({
	id: "pingGate",
	label: "Ping Gate",
	render: () => (
		<div>
			<StepText>
				Are you sure you want to ping gate{" "}
				<b>{values.name || values.serialNumber}</b>?
			</StepText>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="green"
					onClick={handleSubmit}
					disabled={isSubmitting}
				>
					Ping
				</Button>,
			]}
		/>
	),
});

export const openGateStep = ({
	handleSubmit,
	isSubmitting,
	values,
	wizardProps,
}) => ({
	id: "openGate",
	label: "Open Gate",
	render: () => (
		<div>
			<StepText>
				Are you sure you want to open gate{" "}
				<b>{values.name || values.serialNumber}</b>?
			</StepText>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="green"
					onClick={handleSubmit}
					disabled={isSubmitting}
				>
					Open
				</Button>,
			]}
		/>
	),
});

export const closeGateStep = ({
	handleSubmit,
	isSubmitting,
	values,
	wizardProps,
}) => ({
	id: "closeGate",
	label: "Close Gate",
	render: () => (
		<div>
			<StepText>
				Are you sure you want to close gate{" "}
				<b>{values.name || values.serialNumber}</b>?
			</StepText>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="red"
					onClick={handleSubmit}
					disabled={isSubmitting}
				>
					Close
				</Button>,
			]}
		/>
	),
});

export const groupStep = ({
	handleSubmit,
	isSubmitting,
	values,
	wizardProps,
	setFieldValue,
}) => ({
	id: "group",
	label: "Group",
	render: () => (
		<div>
			<StepText>What is the name of the group?</StepText>
			<EditableInputField
				type="text"
				name="groupName"
				value={values.groupName || ""}
				useLabel={"Group Name"}
				onChange={(event) => {
					setFieldValue("groupName", event.target.value);
				}}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="green"
					onClick={handleSubmit}
					disabled={isSubmitting || !String(values.groupName || "").trim()}
				>
					{values.mode === "add-group" ? "Add" : "Update"}
				</Button>,
			]}
		/>
	),
});

export const didNotInstallStep = ({
	handleSubmit,
	isSubmitting,
	values,
	wizardProps,
	setFieldValue,
}) => ({
	id: "did-not-install",
	render: () => (
		<div>
			<StepText>What is the reason this gate is not being installed?</StepText>
			<EditableInputField
				type="text"
				name="didNotInstallReason"
				value={values.didNotInstallReason || ""}
				useLabel={"Reason"}
				onChange={(event) => {
					setFieldValue("didNotInstallReason", event.target.value);
				}}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="green"
					onClick={handleSubmit}
					disabled={
						isSubmitting || !String(values.didNotInstallReason || "").trim()
					}
				>
					Done
				</Button>,
			]}
		/>
	),
});

export const removeStatusStep = ({
	handleSubmit,
	isSubmitting,
	wizardProps,
}) => ({
	id: "removeStatus",
	label: "Remove Status",
	render: () => (
		<div>
			<StepText>Are you sure you want to remove this status?</StepText>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="red"
					onClick={handleSubmit}
					disabled={isSubmitting}
				>
					Remove
				</Button>,
			]}
		/>
	),
});

export const auditLogStep = ({ wizardProps, values }) => ({
	id: "audit-log",
	render: () => (
		<TableLayout
			data={values.auditLogs || []}
			columns={[
				{
					Header: "Description",
					id: "Description",
					accessor: (d) => d.Description,
				},
				{
					Header: "User",
					id: "User",
					accessor: (d) => (d.User ? d.User.Email : ""),
				},
				{
					Header: "Date",
					id: "Date",
					accessor: (d) => d.CreatedOn,
				},
			]}
			sortable={false}
		/>
	),
	footer: () => (
		<WizardNavigation
			rightItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Close
				</Button>,
			]}
		/>
	),
});

export const laneStep = ({
	handleSubmit,
	isSubmitting,
	values,
	wizardProps,
	setFieldValue,
}) => {
	const { lane } = wizardProps;
	let note = null;
	let showNote = false;
	if (lane) {
		showNote = !values.isReversible && !!lane.Node2;

		note = (
			<Note>
				Note: Please remove the second gate kit before changing this lane from
				reversible to one-way.
			</Note>
		);
	}

	return {
		id: "lane",
		label: "Lane",
		render: () => {
			return (
				<div>
					<StepText>What is the name of this lane?</StepText>
					<EditableInputField
						type="text"
						name="laneName"
						value={values.laneName || ""}
						useLabel={"Lane Name"}
						onChange={(event) => {
							setFieldValue("laneName", event.target.value);
						}}
					/>
					<Toggle
						label={"Reversible"}
						checked={values.isReversible}
						onChange={(isReversible) => {
							setFieldValue("isReversible", isReversible);
						}}
					/>
					{showNote && note}

					<FieldWrapper>
						<Label>Lane group:</Label>

						<FieldContent>
							<Dropdown
								isClearable={true}
								onChange={(value) => {
									setFieldValue("groupName", value ? value.value : null);

									if (value) {
										const group = wizardProps.groups.find(
											(e) => e.Name === value.value
										);

										if (group)
											setFieldValue(
												"nextOrder",
												group.Lanes ? group.Lanes.length : 0
											);
									}
								}}
								options={wizardProps.groups
									.map((e) => ({
										value: e.Name,
										label: e.Name,
									}))
									.filter((e) => e.value !== "Ungrouped")}
								value={
									wizardProps.groups
										.map((e) => ({
											value: e.Name,
											label: e.Name,
										}))
										.find(
											(e) =>
												e.value === values.groupName && e.value !== "Ungrouped"
										) || null
								}
							/>
						</FieldContent>
					</FieldWrapper>
				</div>
			);
		},
		footer: () => (
			<WizardNavigation
				leftItems={[
					<Button key="cancel" color="blue" onClick={wizardProps.close}>
						Cancel
					</Button>,
				]}
				rightItems={[
					<Button
						key="submit"
						color="green"
						onClick={handleSubmit}
						disabled={isSubmitting || showNote}
					>
						{values.mode === "add-lane" ? "Add" : "Update"}
					</Button>,
				]}
			/>
		),
	};
};

export const deleteGroupStep = ({
	handleSubmit,
	isSubmitting,
	values,
	wizardProps,
}) => ({
	id: "deleteGroup",
	label: "Delete Group",
	render: () => (
		<div>
			<StepText>
				Are you sure you want to delete group <b>{values.groupName}</b>?
			</StepText>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="red"
					onClick={handleSubmit}
					disabled={isSubmitting}
				>
					Delete
				</Button>,
			]}
		/>
	),
});

export const deleteLaneStep = ({
	handleSubmit,
	isSubmitting,
	values,
	wizardProps,
}) => ({
	id: "deleteLane",
	label: "Delete Lane",
	render: () => (
		<div>
			<StepText>
				Are you sure you want to delete lane <b>{values.laneName}</b>? This will
				delete any gate controller and beacon mappings on this lane as well.
				This action CANNOT be undone.
			</StepText>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="red"
					onClick={handleSubmit}
					disabled={isSubmitting}
				>
					Delete
				</Button>,
			]}
		/>
	),
});

export const adminEditStep = ({
	handleSubmit,
	isSubmitting,
	setFieldValue,
	values,
	wizardProps,
}) => ({
	id: "adminEditStep",
	label: "Edit Hardware",
	render: () => (
		<div>
			<StepTitle>
				{wizardProps.node.HardwareID}
				{wizardProps.node.SerialNumber && ` (${wizardProps.node.SerialNumber})`}
			</StepTitle>

			<FieldWrapper>
				<Label>Status</Label>
				<FieldContent>
					<Dropdown
						options={Object.keys(wizardProps.hardwareStatuses).map(
							(status) => ({
								value: status,
								label: wizardProps.hardwareStatuses[status],
							})
						)}
						value={{
							value: values.status,
							label: wizardProps.hardwareStatuses[values.status],
						}}
						onChange={(value) => {
							setFieldValue("status", value.value);
						}}
					/>
				</FieldContent>
			</FieldWrapper>

			{wizardProps.currentUserIsAdmin && (
				<FieldWrapper>
					<Label>Client Ownership</Label>

					<FieldContent>
						<Dropdown
							isClearable={true}
							onChange={(value) =>
								setFieldValue("clientId", value ? value.value : null)
							}
							options={wizardProps.clients}
							value={wizardProps.clients.find(
								(e) => e.value === values.clientId
							)}
						/>
					</FieldContent>
				</FieldWrapper>
			)}

			<PowerSettings
				isAdmin={wizardProps.currentUserIsAdmin}
				isOnline={values.isOnline}
				setFieldValue={setFieldValue}
				values={values}
			/>

			<FieldWrapper>
				<Label>Sites</Label>
				<NodeSites node={wizardProps.node} />
			</FieldWrapper>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="submit" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="green"
					onClick={handleSubmit}
					disabled={isSubmitting}
				>
					Update
				</Button>,
			]}
		/>
	),
});

export const adminPingGateStep = ({
	handleSubmit,
	isSubmitting,
	values,
	wizardProps,
}) => ({
	id: "adminPingGate",
	label: "Ping Gate",
	render: () => (
		<div>
			<StepText>
				Are you sure you want to ping gate{" "}
				<b>{values.name || values.serialNumber}</b>?
			</StepText>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="green"
					onClick={handleSubmit}
					disabled={isSubmitting}
				>
					Ping
				</Button>,
			]}
		/>
	),
});

export const adminAddHardwareStep = ({ wizardProps }) => ({
	id: "adminAddHardware",
	label: "Add Gate Controllers",
	render: () => <ImportHardware />,
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
		/>
	),
});

export const editPowerStrengthStep = ({
	handleSubmit,
	isSubmitting,
	setFieldValue,
	values,
	wizardProps,
}) => ({
	id: "editPowerStrength",
	label: "Edit Power Strength",
	render: () => {
		return (
			<SiteLevelPowerSettings
				values={values}
				setFieldValue={setFieldValue}
				isAdmin={wizardProps.isAdmin}
			/>
		);
	},
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="green"
					onClick={handleSubmit}
					disabled={isSubmitting || !values.canUpdateSiteLevelPowerSettings}
				>
					Update
				</Button>,
			]}
		/>
	),
});

const SiteLevelPowerSettings = (props) => {
	const [state, setState] = useState({
		isPinging: true,
		isOnline: false,
	});

	const pingDeviceQuery = useLazyQueryData(gql`
		query ($nodeId: Int!, $siteId: Int) {
			pingDevice(nodeId: $nodeId, siteId: $siteId)
		}
	`);

	const getLatestNodeData = useLazyQueryData(
		gql`
			query ($NodeID: Int!) {
				getNodeByNodeID(NodeID: $NodeID) {
					GateTxPower
					TxPower
					Attenuation
				}
			}
		`,
		{ NodeID: props.values.nodeId }
	);

	useEffect(() => {
		async function pingNode() {
			const response = await pingDeviceQuery({
				siteId: props.values.siteId,
				nodeId: props.values.nodeId,
			});

			const isOnline = response.data ? response.data.pingDevice : false;

			const { data } = await getLatestNodeData();

			setState({
				isPinging: false,
				isOnline: isOnline,
			});

			props.setFieldValue("canUpdateSiteLevelPowerSettings", isOnline);
			props.setFieldValue("gateTxPower", data.getNodeByNodeID.GateTxPower);
			props.setFieldValue("txPower", data.getNodeByNodeID.TxPower);
			props.setFieldValue("attenuation", data.getNodeByNodeID.Attenuation);
		}

		pingNode();
	}, []);

	if (state.isPinging) {
		return (
			<Fragment>
				<Loader />
				<div style={{ textAlign: "center", marginTop: "16px" }}>
					Pinging the device...
				</div>
			</Fragment>
		);
	}

	return (
		<Fragment>
			<StepTitle>Please set the power strength</StepTitle>
			<PowerSettings
				isAdmin={props.isAdmin}
				isOnline={state.isOnline}
				values={props.values}
				setFieldValue={props.setFieldValue}
			/>
		</Fragment>
	);
};

export const createInstallationStep = ({
	handleSubmit,
	isSubmitting,
	values,
	wizardProps,
	setFieldValue,
}) => ({
	id: "createInstallation",
	label: "Create Installation",
	render: () => (
		<div>
			<StepText>Create from an existing installation?</StepText>
			<MultiChoiceButton
				selected={!values.createFromExistingInstallation}
				onClick={() => {
					setFieldValue("createFromExistingInstallation", false);
				}}
			>
				No
			</MultiChoiceButton>
			{wizardProps.isAdmin && (
				<MultiChoiceButton
					selected={values.createFromExistingInstallation}
					onClick={() => {
						setFieldValue("createFromExistingInstallation", true);
					}}
				>
					Yes
				</MultiChoiceButton>
			)}

			{values.createFromExistingInstallation && (
				<InstallationLookup
					onChange={(id) => setFieldValue("parentInstallationId", id)}
				/>
			)}
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="green"
					onClick={handleSubmit}
					disabled={
						isSubmitting ||
						(values.createFromExistingInstallation &&
							!values.parentInstallationId)
					}
				>
					Create
				</Button>,
			]}
		/>
	),
});

export function deleteInstallationStep({
	close,
	handleSubmit,
	isSubmitting,
	keyStrokeHandler,
}) {
	return {
		id: "deleteInstallation",
		label: "Delete Installation",
		render: () => {
			return (
				<div>
					<StepText>
						<div style={{ textAlign: "center" }}>
							Are you sure you want to delete this installation. This action
							CANNOT be undone.
						</div>
					</StepText>
				</div>
			);
		},
		footer: () => {
			return (
				<WizardNavigation
					leftItems={[
						<Button key="previous" onClick={close} color="blue">
							Cancel
						</Button>,
					]}
					rightItems={[
						<Button
							key="submit"
							color="red"
							onClick={handleSubmit}
							disabled={isSubmitting}
							keyStrokeHandler={keyStrokeHandler}
						>
							Delete
						</Button>,
					]}
				/>
			);
		},
	};
}

export function removeInstallationFromSiteStep({
	close,
	handleSubmit,
	isSubmitting,
	keyStrokeHandler,
	wizardProps,
}) {
	return {
		id: "removeInstallationFromSite",
		label: "Remove Installation",
		render: () => {
			return (
				<div>
					<StepText>
						<div style={{ textAlign: "center" }}>
							{`Are you sure you want to remove this installation from ${wizardProps.site.Name}.`}
						</div>
					</StepText>
				</div>
			);
		},
		footer: () => {
			return (
				<WizardNavigation
					leftItems={[
						<Button key="previous" onClick={close} color="blue">
							Cancel
						</Button>,
					]}
					rightItems={[
						<Button
							key="submit"
							color="red"
							onClick={handleSubmit}
							disabled={isSubmitting}
							keyStrokeHandler={keyStrokeHandler}
						>
							Remove
						</Button>,
					]}
				/>
			);
		},
	};
}

export const gateCloseRelayStep = ({
	previous,
	goTo,
	values,
	setFieldValue,
	keyStrokeHandler,
	next,
}) => ({
	id: "gateCloseRelay",
	label: "Gate Close Relay",
	render: () => (
		<div>
			<StepTitle>
				Is the gate controller connected to the close relay?
			</StepTitle>
			<MultiChoiceButton
				selected={!values.gateCloseRelay}
				onClick={() => {
					setFieldValue("gateCloseRelay", false);
				}}
			>
				No
			</MultiChoiceButton>
			<MultiChoiceButton
				selected={values.gateCloseRelay}
				onClick={() => {
					setFieldValue("gateCloseRelay", true);
				}}
			>
				Yes
			</MultiChoiceButton>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button key="previous" onClick={previous} color="blue">
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={() => {
						if (values.editFromSummary) {
							goTo("summary");
						} else {
							next();
						}
					}}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.editFromSummary ? "Review" : "Next"}
				</Button>,
			]}
		/>
	),
});
