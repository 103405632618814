import * as Yup from "yup";
import WizardLayout, { StepTitle } from "../WizardLayout";
import Alert from "react-s-alert";
import Button from "../../layout/Button";
import EditableInputField from "../../layout/EditableInputField";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import WizardNavigation from "../WizardNavigation";
import { useMutateData } from "../../../hooks";
import gql from "graphql-tag";
import { AppContext } from "../../../context/app-context";

const name = ({
	close,
	handleSubmit,
	isSubmitting,
	values,
	keyStrokeHandler,
}) => ({
	id: "name",
	label: "Name",
	render: () => (
		<div>
			<StepTitle>What is the name of the operator?</StepTitle>
			<EditableInputField
				type="text"
				name="name"
				value={values.name || ""}
				useLabel="Operator Name"
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				<Button key="name" color="red" onClick={close}>
					Cancel
				</Button>
			}
			rightItems={[
				<Button
					key="create"
					color="blue"
					onClick={handleSubmit}
					disabled={isSubmitting || !values.name}
					keyStrokeHandler={keyStrokeHandler}
				>
					Create
				</Button>,
			]}
		/>
	),
});

CreateOperatorWizard.propTypes = {
	close: PropTypes.func.isRequired,
};

CreateOperatorWizard.defaultProps = {
	close: () => {},
};

function CreateOperatorWizard(props) {
	const {
		dispatch: { setOperator },
	} = useContext(AppContext);
	const createOperator = useMutateData(gql`
		mutation ($name: String) {
			createOperator(name: $name) {
				OperatorID
				Name
			}
		}
	`);

	const onSubmit = async (values, { setSubmitting }) => {
		setSubmitting(true);

		try {
			const response = await createOperator({
				variables: {
					name: values.name,
				},
			});

			const operator = response?.data?.createOperator;

			setOperator(operator);

			if (operator) {
				Alert.success("Operator created");

				return new Promise(() =>
					setTimeout(() => {
						window.location.href = `/operator/${operator.OperatorID}`;
					}, 1000)
				);
			}
		} catch (error) {
			setSubmitting(false);
			Alert.error("Something went wrong. Please try again.");
		}
	};

	return (
		<WizardLayout
			close={props.close}
			title="Create Operator"
			values={[
				{
					name: "name",
					validator: Yup.string(),
				},
			]}
			onSubmit={onSubmit}
			steps={[name]}
			initialStep={0}
			wizardProps={props}
		/>
	);
}

export default CreateOperatorWizard;
