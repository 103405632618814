import React from "react";
import styled from "styled-components";
import Card from "../../layout/Card";
import Input from "../../layout/Input";
import _ from "lodash";
import { supportedColors } from "./Variables";
import { getAllVariableGroups } from "../request-wizard/helpers";

const Variable = styled.div`
	:hover {
		cursor: pointer;
		opacity: 0.6;
	}
	background-color: #e6e6e6;
	margin: 10px 0 10px 0;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 5px;
	padding-bottom: 5px;
	border-radius: 3px;
	border-left: 4px solid rgb(27, 200, 142);
`;

export function InputWithVariables(props) {
	const key = props.id;
	const prefix = props.values[`${key}Prefix`] || "";
	const inputValue = props.values[key];
	const value = `${prefix}${inputValue.substring(prefix.length)}`;
	const setFieldValue = props.setFieldValue;
	const showVariableSuggestions = props.values.showVariableSuggestions;
	const watch = (event) => {
		if (
			event.target.selectionStart > 0 &&
			value?.substring(
				event.target.selectionStart - 1,
				event.target.selectionStart
			) === "{"
		) {
			setFieldValue("showVariableSuggestions", true);
		}
	};

	const groups = getAllVariableGroups(props.values).filter(
		(group) => group.Name !== "Transform"
	);

	const showVariables = !!(
		showVariableSuggestions &&
		value?.includes("{") &&
		_.flatten(groups.map((g) => g.variables)).length
	);
	return (
		<>
			<Input
				type="text"
				textArea={props.type === "textarea"}
				name={key}
				value={value || ""}
				onChange={(event) => {
					setFieldValue(key, event.target.value);
				}}
				onKeyUp={watch}
				onFocus={watch}
				onClick={watch}
			/>
			{showVariables && (
				<Card style={{ marginTop: "0", overflowY: "auto", maxHeight: "20vh" }}>
					{groups.map((grp) => (
						<>
							<span style={{ color: "gray" }}>{grp.name}</span>
							{grp?.variables?.map((variable, variableIndex) => (
								<Variable
									style={{
										borderLeft: `4px solid ${
											supportedColors[grp.color]?.type ??
											supportedColors.default
										}`,
									}}
									onClick={() => {
										setFieldValue(key, `{{{${variable.value}}}}`);
										setFieldValue("showVariableSuggestions", false);
									}}
									key={`variableSuggestion${variableIndex}`}
								>
									{variable.value}
									{variable.variableValue ? ` - ${variable.variableValue}` : ""}
								</Variable>
							))}
						</>
					))}
				</Card>
			)}
		</>
	);
}
