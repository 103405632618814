import * as Yup from "yup";
import _ from "lodash";
import * as steps from "./steps";
import * as genericSteps from "../Generics";
import React, { useRef, useState } from "react";
import WizardLayout from "../WizardLayout";
import gql from "graphql-tag";
import { useMutateData } from "../../../hooks";
import Alert from "react-s-alert";
import {
	AUTHORIZATION_TYPES,
	TIMESTAMP_FORMAT_OPTIONS,
	TRANSFORMATIONS,
} from "../../../helpers/constants";
import {
	LANGUAGE_MODES,
	VARIABLE_TYPE_OPTIONS,
	TIMESTAMP_BUILDER_UNIT_OPTIONS,
	RESPONSE_METHODS,
	HANDLER_ACTIONS,
} from "./constants";
import { newInsertHandler } from "./helpers";

function fromResponse(request) {
	if (!request?.Response) {
		return [];
	}

	return request.Response.map((resp) => ({
		path: resp.Path,
		variable: resp.Variable,
		label: resp.Label,
		description: resp.Description,
		type: resp.Type,
		method: resp.Method,
		Predicate: resp.Predicate,
	}));
}

function toResponse(values) {
	if (!values.response) {
		return null;
	}

	return values.response.map((resp) => ({
		Method: resp.method,
		Path: resp.path,
		Variable: resp.variable,
		Type: resp.type,
		Label: resp.label,
		Description: resp.description,
		Predicate: resp.Predicate,
	}));
}

function fromBody(request) {
	switch (request?.BodyLanguage) {
		case LANGUAGE_MODES.JSON:
			return { body: JSON.stringify(JSON.parse(request.Body), undefined, 4) };

		case LANGUAGE_MODES.MULTIPART_FORM_DATA: {
			const body = JSON.parse(request.Body);
			return {
				body: JSON.stringify(body.body, undefined, 4),
				csvSchema: body.csvSchemas[0],
			};
		}
		case LANGUAGE_MODES.XML:
		case LANGUAGE_MODES.SOAP:
		case LANGUAGE_MODES.URL_ENCODED:
			return { body: request.Body };
		default:
			return { body: "" };
	}
}

function fromMapping(mapping) {
	if (!mapping) return null;
	const result = JSON.parse(mapping);
	for (const valueToMapFrom of Object.values(result)) {
		valueToMapFrom.type = VARIABLE_TYPE_OPTIONS.find(
			(o) => o.value === valueToMapFrom.type
		);
	}

	return result;
}

function toMapping(mapping) {
	if (!mapping) return null;

	const result = { ...mapping };
	for (const valueToMapFrom of Object.values(result)) {
		valueToMapFrom.type = valueToMapFrom.type.value;
	}
	return JSON.stringify(result);
}

function fromTimestampBuilder(b) {
	if (!b) {
		return {};
	}

	return {
		From: b.From,
		Add: _.pick(
			b.Add,
			TIMESTAMP_BUILDER_UNIT_OPTIONS.map((o) => o.value)
		),
	};
}

export default function RequestWizard(props) {
	const request = props.request;

	const [method, setMethod] = useState(request?.Method || "POST");
	const [externallyConsumed, setExternallyConsumed] = useState(
		request?.ExternallyConsumed || false
	);

	const editorRef = useRef(null);

	const isSFTP = method === "SFTP";
	const isAdding = props.mode === "add";

	const requestBasedVariables = props.requests
		? _.uniqBy(
				_.flatten(
					props.requests
						.map((r) =>
							r.Response?.map((resp) => ({
								value: resp.Variable,
								label: resp.Label,
								description: resp.Description,
								type: resp.Type,
							}))
						)
						.filter((variable) => variable)
				),
				"value"
		  )
		: [];

	const headers = request?.Headers
		? request?.Headers?.map((param) => ({
				key: param.Key,
				value: param.Value,
				description: param.Description,
		  }))
		: [{ key: "Content-Type", value: "application/json", description: "" }];
	const { body, csvSchema } = fromBody(request);
	const response = fromResponse(request);

	const filterResponse = response.find(
		(r) => r.method === RESPONSE_METHODS.FILTER
	);

	const initialValues = [
		{
			name: "name",
			value: request?.Name || "",
			validator: Yup.string(),
		},
		{
			name: "method",
			value: request?.Method || "POST",
			validator: Yup.string(),
		},
		{
			name: "url",
			value: request?.URL || "",
			validator: Yup.string(),
		},
		{
			name: "externallyConsumed",
			value: request?.ExternallyConsumed || false,
			validator: Yup.string(),
		},
		{
			name: "port",
			value: request?.Metadata?.Port || "22",
			validator: Yup.string(),
		},
		{
			name: "hasParams",
			value: request?.Params ? true : false,
			validator: Yup.string(),
		},
		{
			name: "userInputVariables",
			value: request?.UserInputVariables?.map((variable) => ({
				Key: variable.Key,
				Type: variable.Type,
				Label: variable.Label,
				Description: variable.Description,
			})),
			validator: Yup.array().nullable(),
		},
		{
			name: "params",
			value: request?.Params
				? request?.Params?.map((param) => ({
						key: param.Key,
						value: param.Value,
						description: param.Description,
				  }))
				: [{ key: "", value: "", description: "" }],
			validator: Yup.array(),
		},
		{
			name: "shouldAuthorize",
			value: request?.AuthorizationType ? true : false,
			validator: Yup.string(),
		},
		{
			name: "authorizationType",
			value: request?.AuthorizationType,
			validator: Yup.string().nullable(),
		},
		{
			name: "basicAuthUsername",
			value: request?.BasicAuthCredentials?.Username,
			validator: Yup.string(),
		},
		{
			name: "basicAuthPassword",
			value: request?.BasicAuthCredentials?.Password,
			validator: Yup.string(),
		},
		{
			name: "bearerToken",
			value: request?.BearerToken,
			validator: Yup.string().nullable(),
		},
		{
			name: "hasHeaders",
			value: request?.Headers ? true : false,
			validator: Yup.string(),
		},
		{
			name: "headers",
			value: request?.AuthorizationType
				? [
						{
							key: "Authorization",
							value: `${request?.AuthorizationType} ${
								request?.AuthorizationType === AUTHORIZATION_TYPES.BASIC
									? btoa(
											`${request?.BasicAuthCredentials?.Username}:${request?.BasicAuthCredentials?.Password}`
									  )
									: request?.BearerToken
							}`,
							readonly: true,
						},
						...headers,
				  ]
				: headers,
			validator: Yup.array(),
		},
		{
			name: "hasTransformations",
			value: request?.Transformations ? true : false,
			validator: Yup.string(),
		},
		{
			name: "transformations",
			value: request?.Transformations
				? request?.Transformations?.map((transform) => ({
						variable: {
							value: transform.Variable?.Name,
							label: transform.Variable?.Label,
							type: transform.Variable?.Type,
						},
						name: transform.Name,
						label: transform.Label,
						transformation: TRANSFORMATIONS[transform.Variable?.Type].find(
							(t) => t.value === transform.Transformation
						),
						template: transform.Template,
						format: TIMESTAMP_FORMAT_OPTIONS.find(
							(o) => o.value === transform.Format
						),
						hours: transform.Hours,
						characterLength: transform.CharacterLength,
						key: transform.Key,
						find: transform.Find,
						replace: transform.Replace,
						mapping: fromMapping(transform.Mapping),
						digits: transform.Digits,
						ternary: transform.Ternary
							? {
									condition: transform.Ternary.Condition,
									ifTrue: transform.Ternary.IfTrue,
									ifFalse: transform.Ternary.IfFalse,
							  }
							: {},
						timestampBuilder: fromTimestampBuilder(transform.TimestampBuilder),
				  }))
				: [
						{
							variable: null,
							name: "",
							label: "",
							transformation: "",
							template: null,
							format: null,
							mode: "edit",
						},
				  ],
			validator: Yup.array(),
		},
		{
			name: "body",
			value: body,
			validator: Yup.string(),
		},
		{
			name: "languageMode",
			value: request?.BodyLanguage || LANGUAGE_MODES.JSON,
			validator: Yup.string(),
		},
		{
			name: "responseMethod",
			value:
				response.find(
					(r) =>
						r.method === RESPONSE_METHODS.MAP_TO_VARIABLE ||
						r.method === RESPONSE_METHODS.RETURN_RESPONSE
				)?.method ?? RESPONSE_METHODS.NONE,
			validator: Yup.string(),
		},
		{
			name: "response",
			value: response,
			validator: Yup.array(),
		},
		{
			name: "handlerMethod",
			value: request?.Handler?.Method || "Nothing",
			validator: Yup.string(),
		},
		{
			name: "validation",
			value: request?.Handler?.Validation
				? request?.Handler?.Validation?.map((item) => ({
						prop: item.Prop,
						object: item.Object,
						objectProp: item.ObjectProp,
						abortRequest: item.AbortRequest,
						propSource: item.PropSource,
				  }))
				: [{ prop: "", object: "", objectProp: "", mode: "edit" }],
			validator: Yup.array(),
		},
		{
			name: "inserts",
			value: request?.Handler?.Inserts
				? request.Handler.Inserts.map((i) => ({
						object: i.Object,
						action: i.Action || HANDLER_ACTIONS.INSERT,
						properties: i.Properties.map((prop) => ({
							prop: prop.Prop,
							propSource: prop.PropSource,
							objectProp: prop.ObjectProp,
						})),
				  }))
				: [newInsertHandler()],
			validator: Yup.array(),
		},
		{
			name: "contextProperties",
			value: request?.Handler?.Context?.Properties
				? request.Handler.Context?.Properties.map((prop) => ({
						prop: prop.Prop,
						propSource: prop.PropSource,
						objectProp: prop.ObjectProp,
				  }))
				: [
						{
							objectProp: "",
							prop: "",
							propSource: "",
							mode: "edit",
						},
				  ],
			validator: Yup.array(),
		},
		{
			name: "reports",
			value: request?.Handler?.Reports
				? request?.Handler?.Reports?.map((item) => ({
						report: item.Report,
						name: item.Name,
						prop: item.Prop,
				  }))
				: [{ report: "", name: "", prop: "", mode: "edit" }],
			validator: Yup.array(),
		},
		{
			name: "filterResponse",
			value: !_.isNil(filterResponse),
			validator: Yup.bool(),
		},
		{
			name: "conditionalResponseFiltering",
			value: !_.isNil(filterResponse?.Predicate),
			validator: Yup.bool(),
		},
		{
			name: "hasRules",
			value: request?.Rules ? true : false,
			validator: Yup.string(),
		},
		...[{ prefix: "Validate" }, { prefix: "Pre" }, { prefix: "Post" }].map(
			(requestRule) => ({
				name: `${requestRule.prefix.toLowerCase()}RequestRules`,
				value: request?.Rules?.[`${requestRule.prefix}Request`]
					? request?.Rules?.[`${requestRule.prefix}Request`]?.map((rule) => ({
							type: rule.Type,
							variable: rule.Variable,
							responseCode: rule.ResponseCode,
							condition: rule.Condition,
							outcome: rule.Outcome,
							request: rule.Request,
							unsetVariable: rule.UnsetVariable,
							conditionalValue: rule.ConditionalValue,
							derivedVariable: rule.DerivedVariable,
					  }))
					: [],
				validator: Yup.array(),
			})
		),
		{
			name: "requests",
			value: props.requests
				? props.requests.map((r) => ({
						value: r.RequestID,
						label: r.Name,
				  }))
				: [],
			validator: Yup.array(),
		},
		{
			name: "variables",
			value: requestBasedVariables,
			validator: Yup.array(),
		},
		{
			name: "variableGroups",
			value: [
				{
					name: "Actions",
					variables: [
						{
							value: "CreateVariable",
							label: "Create Variable",
							type: "Action",
						},
					],
				},
				{
					name: "Requests",
					variables: requestBasedVariables,
					color: "orange",
				},
				...(props.variableGroups?.map((variableGroup) => ({
					name: variableGroup.Name,
					color: variableGroup.Name === "Custom" ? "blue" : "default",
					variables: variableGroup.Variables?.map((variable) => ({
						value: variable.Name,
						variableValue: variable.Value,
						label: variable.Label,
						description: variable.Description,
						type: variable.Type,
					})),
				})) || []),
			],
			validator: Yup.array(),
		},
		{
			name: "sftpAuthMethod",
			value: request?.SFTPConnection?.Password ? "Password" : "PrivateKey",
			validator: Yup.string(),
		},
		{
			name: "hostname",
			value: request?.URL,
			validator: Yup.string(),
		},
		{
			name: "username",
			value: request?.SFTPConnection?.Username,
			validator: Yup.string(),
		},
		{
			name: "password",
			value: request?.SFTPConnection?.Password,
			validator: Yup.string().nullable(),
		},
		{
			name: "privateKey",
			value: request?.SFTPConnection?.PrivateKey,
			validator: Yup.string().nullable(),
		},
		{
			name: "port",
			value: request?.SFTPConnection?.Port || "22",
			validator: Yup.string(),
		},
		{
			name: "csvSchema",
			value: csvSchema || {
				headers: [""],
				values: [""],
				writeHeaders: false,
				selectedCellIndex: null,
				delimiter: "",
				id: "",
			},
			validator: Yup.object(),
		},
		{
			name: "responseBody",
			value: request?.ResponseBody,
			validator: Yup.string().nullable(),
		},
	];

	const createRequest = useMutateData(gql`
		mutation (
			$context: IntegrationContext!
			$organizationId: Int
			$name: String!
			$url: String!
			$method: String!
			$params: [RequestParamInput]
			$authorizationType: RequestAuthType
			$headers: [RequestParamInput]
			$transformations: [RequestTransformationInput]
			$body: String
			$bodyLanguage: String
			$response: [ResponseMapItemInput]
			$handler: RequestHandlerInput
			$rules: RequestRulesInput
			$basicAuthCredentials: BasicAuthCredentialsInput
			$bearerToken: String
			$sftpConnection: SFTPConnectionInput
			$externallyConsumed: Boolean
			$responseBody: String
			$userInputVariables: [UserInputVariableInput!]
		) {
			createRequest(
				context: $context
				organizationId: $organizationId
				name: $name
				url: $url
				method: $method
				params: $params
				authorizationType: $authorizationType
				headers: $headers
				transformations: $transformations
				body: $body
				bodyLanguage: $bodyLanguage
				response: $response
				handler: $handler
				rules: $rules
				basicAuthCredentials: $basicAuthCredentials
				bearerToken: $bearerToken
				sftpConnection: $sftpConnection
				externallyConsumed: $externallyConsumed
				responseBody: $responseBody
				userInputVariables: $userInputVariables
			)
		}
	`);

	const updateRequest = useMutateData(gql`
		mutation (
			$requestId: Int!
			$name: String!
			$url: String!
			$method: String!
			$params: [RequestParamInput]
			$authorizationType: RequestAuthType
			$headers: [RequestParamInput]
			$transformations: [RequestTransformationInput]
			$body: String
			$bodyLanguage: String
			$response: [ResponseMapItemInput]
			$handler: RequestHandlerInput
			$rules: RequestRulesInput
			$basicAuthCredentials: BasicAuthCredentialsInput
			$bearerToken: String
			$sftpConnection: SFTPConnectionInput
			$externallyConsumed: Boolean
			$responseBody: String
			$userInputVariables: [UserInputVariableInput!]
		) {
			updateRequest(
				requestId: $requestId
				name: $name
				url: $url
				method: $method
				params: $params
				authorizationType: $authorizationType
				headers: $headers
				transformations: $transformations
				body: $body
				bodyLanguage: $bodyLanguage
				response: $response
				handler: $handler
				rules: $rules
				basicAuthCredentials: $basicAuthCredentials
				bearerToken: $bearerToken
				sftpConnection: $sftpConnection
				externallyConsumed: $externallyConsumed
				responseBody: $responseBody
				userInputVariables: $userInputVariables
			)
		}
	`);

	const deleteRequest = useMutateData(gql`
		mutation ($requestId: Int!) {
			deleteRequest(requestId: $requestId)
		}
	`);

	const duplicateRequest = useMutateData(gql`
		mutation ($requestId: Int!) {
			duplicateRequest(requestId: $requestId)
		}
	`);

	const attemptFileTransferConnection = useMutateData(gql`
		mutation (
			$hostname: String!
			$username: String!
			$password: String
			$privateKey: String
			$port: String
		) {
			attemptFileTransferConnection(
				hostname: $hostname
				username: $username
				password: $password
				privateKey: $privateKey
				port: $port
			)
		}
	`);

	const onSubmit = async (values) => {
		try {
			if (props.mode === "delete") {
				await deleteRequest({
					variables: {
						requestId: request.RequestID,
					},
				});
				props.close();
				return;
			}

			if (props.mode === "duplicate") {
				await duplicateRequest({
					variables: {
						requestId: request.RequestID,
					},
				});
				props.close();
				return;
			}

			const isSFTPMethod = values.method === "SFTP";
			const base = { name: values.name, method: values.method };
			const model = isSFTPMethod
				? {
						...base,
						url: values.hostname,
						sftpConnection: {
							Username: values.username,
							Password:
								values.sftpAuthMethod === "Password" ? values.password : null,
							PrivateKey:
								values.sftpAuthMethod === "PrivateKey"
									? values.privateKey
									: null,
							Port: values.port,
							Directory: values.directory,
						},
				  }
				: {
						...base,
						externallyConsumed,
						userInputVariables: values.userInputVariables,
						url: values.url,
						params: values.hasParams
							? values.params.map((param) => ({
									Key: param.key,
									Value: param.value,
									Description: param.description,
							  }))
							: null,
						authorizationType: values.shouldAuthorize
							? values.authorizationType
							: null,
						headers: values.hasHeaders
							? values.headers
									.filter((h) => !h.readonly)
									.map((param) => ({
										Key: param.key,
										Value: param.value,
										Description: param.description,
									}))
							: null,
						transformations: values.hasTransformations
							? values.transformations.map((transformation) => {
									const transformationName =
										transformation.transformation?.value;
									return {
										Variable: {
											Name: transformation.variable?.value,
											Label: transformation.variable?.label,
											Type: transformation.variable?.type,
										},
										Name: transformation.name,
										Label: transformation.label,
										Transformation: transformationName,
										Template: transformation.template,
										Format: transformation.format?.value,
										Hours: transformation.hours
											? parseInt(transformation.hours)
											: null,
										CharacterLength: transformation.characterLength
											? parseInt(transformation.characterLength)
											: null,
										Key: transformation.key ? transformation.key : null,
										Find: transformation.find ? transformation.find : null,
										Replace: transformation.replace
											? transformation.replace
											: null,
										Mapping: toMapping(transformation.mapping),
										Digits: transformation.digits
											? Number(transformation.digits)
											: null,
										Ternary:
											transformationName === "Ternary"
												? {
														Condition: transformation.ternary.condition,
														IfTrue: transformation.ternary.ifTrue,
														IfFalse: transformation.ternary.ifFalse,
												  }
												: null,
										TimestampBuilder:
											transformationName === "GenerateTimestamp"
												? transformation.timestampBuilder
												: null,
									};
							  })
							: null,
						body:
							values.languageMode === LANGUAGE_MODES.MULTIPART_FORM_DATA
								? JSON.stringify({
										body: values.body && JSON.parse(values.body),
										csvSchemas: [
											{
												headers: values.csvSchema.headers,
												values: values.csvSchema.values,
												writeHeaders: values.csvSchema.writeHeaders,
												delimiter: values.csvSchema.delimiter,
												id: values.csvSchema.id,
											},
										],
								  })
								: values.body,
						bodyLanguage: values.languageMode,
						response: toResponse(values),
						rules: values.hasRules
							? {
									ValidateRequest: values.validateRequestRules?.map((rule) => ({
										Type: rule.type,
										Variable: rule.variable,
										ResponseCode: rule.responseCode,
										Condition: rule.condition,
										Outcome: rule.outcome,
										Request: rule.request,
										UnsetVariable: rule.unsetVariable,
										ConditionalValue: rule.conditionalValue,
										DerivedVariable: rule.derivedVariable,
									})),
									PreRequest: values.preRequestRules?.map((rule) => ({
										Type: rule.type,
										Variable: rule.variable,
										ResponseCode: rule.responseCode,
										Condition: rule.condition,
										Outcome: rule.outcome,
										Request: rule.request,
										UnsetVariable: rule.unsetVariable,
										ConditionalValue: rule.conditionalValue,
										DerivedVariable: rule.derivedVariable,
									})),
									PostRequest: values.postRequestRules?.map((rule) => ({
										Type: rule.type,
										Variable: rule.variable,
										ResponseCode: rule.responseCode,
										Condition: rule.condition,
										Outcome: rule.outcome,
										Request: rule.request,
										UnsetVariable: rule.unsetVariable,
										ConditionalValue: rule.conditionalValue,
										DerivedVariable: rule.derivedVariable,
									})),
							  }
							: null,
						handler:
							values.handlerMethod === "Handle"
								? {
										Validation: values.validation?.map((item) => ({
											Prop: item.prop,
											Object: item.object,
											ObjectProp: item.objectProp,
											PropSource: item.propSource,
											AbortRequest: item.abortRequest,
										})),
										Inserts: values.inserts.map((i) => ({
											Object: i.object,
											Action: i.action,
											Properties: i.properties.map((prop) => ({
												Prop: prop.prop,
												PropSource: prop.propSource,
												ObjectProp: prop.objectProp,
											})),
										})),
										Reports: values.reports?.map((item) => ({
											Report: item.report,
											Name: item.name,
											Prop: item.prop,
										})),
										Method: values.handlerMethod,
										Context: {
											Properties: values.contextProperties.map((prop) => ({
												Prop: prop.prop,
												PropSource: prop.propSource,
												ObjectProp: prop.objectProp,
											})),
										},
								  }
								: null,
						basicAuthCredentials:
							values.shouldAuthorize &&
							values.authorizationType === AUTHORIZATION_TYPES.BASIC
								? {
										Username: values.basicAuthUsername,
										Password: values.basicAuthPassword,
								  }
								: null,
						bearerToken:
							values.shouldAuthorize &&
							values.authorizationType === AUTHORIZATION_TYPES.BEARER
								? values.bearerToken
								: null,
						responseBody: values.responseBody,
				  };

			if (props.mode === "add") {
				await createRequest({
					variables: {
						context: props.context,
						organizationId: props.organizationId,
						...model,
					},
				});
			} else if (props.mode === "edit") {
				await updateRequest({
					variables: {
						requestId: request.RequestID,
						...model,
					},
				});
			}

			Alert.success(
				`${isAdding ? "Added" : "Updated"} ${
					isSFTPMethod ? "SFTP Connection" : `${values.method} Request`
				}`
			);
		} catch (error) {
			Alert.error("Something went wrong.");
		}
		props.close();
	};

	const currentSteps = [
		steps.nameStep,
		steps.methodStep(setMethod),
		...(isSFTP
			? []
			: [
					steps.userInputStep,
					steps.externalStep(setExternallyConsumed),
					genericSteps.suggestableInputStep(
						"url",
						"URL",
						"What is the URL for this request?"
					),
					externallyConsumed ? null : steps.paramsStep,
					steps.authorizationStep,
					externallyConsumed ? null : steps.headersStep,
					externallyConsumed ? null : steps.transformStep,
					method !== "POST" || externallyConsumed
						? null
						: steps.bodyStep(editorRef),
					externallyConsumed ? null : steps.responseStep,
					steps.mappingStep,
					externallyConsumed ? null : steps.rulesStep,
			  ]),
		...(isSFTP
			? [
					genericSteps.suggestableInputStep(
						"hostname",
						"Hostname",
						"What is the hostname for this SFTP connection?"
					),
					genericSteps.suggestableInputStep(
						"username",
						"Username",
						"What is the username for this SFTP Connection?"
					),
					steps.sftpPassKeyStep,
					genericSteps.suggestableInputStep(
						"port",
						"Port",
						"What is the port for this SFTP Connection?"
					),
			  ]
			: []),
		steps.summaryStep(attemptFileTransferConnection),
	].filter((s) => s);

	if (props.mode === "delete") {
		return (
			<WizardLayout
				rootTabIndex={null}
				close={props.close}
				title={"Delete Request"}
				onSubmit={onSubmit}
				values={initialValues}
				steps={[steps.deleteStep]}
				initialStep={0}
				wizardProps={props}
			/>
		);
	}

	if (props.mode === "duplicate") {
		return (
			<WizardLayout
				rootTabIndex={null}
				close={props.close}
				title={"Duplicate Request"}
				onSubmit={onSubmit}
				values={initialValues}
				steps={[steps.duplicateStep]}
				initialStep={0}
				wizardProps={props}
			/>
		);
	}

	const width = "1300px";
	return (
		<WizardLayout
			widths={{
				name: "800px",
				method: "800px",
				url: "800px",
				hostname: "800px",
				username: "800px",
				privateKey: "800px",
				params: width,
				authorization: "800px",
				headers: width,
				transform: "1520px",
				body: externallyConsumed ? "800px" : width,
				response: width,
				handlerMethod: "1000px",
				handler: "1300px",
				rules: "1520px",
			}}
			rootTabIndex={null}
			close={props.close}
			title={`${isAdding ? "Add" : "Edit"} ${
				isSFTP ? "SFTP Connection" : `${method} Request`
			}`}
			onSubmit={onSubmit}
			values={initialValues}
			steps={currentSteps}
			initialStep={isAdding ? 0 : currentSteps.length - 1}
			wizardProps={props}
		/>
	);
}
