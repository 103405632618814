import { appliesToStep, nameStep, typeStep, validationStep } from "../steps";
import Button from "../../../layout/Button";
import React from "react";
import { StepTitle } from "../../WizardLayout";
import SummaryTable from "../../SummaryTable";
import WizardNavigation from "../../WizardNavigation";
import rules from "./rules";
import AlertMessage from "../../../layout/AlertMessage";

export default ({
	handleSubmit,
	isSubmitting,
	setFieldValue,
	values,
	goTo,
	wizardProps,
	previous,
	keyStrokeHandler,
}) => {
	const stepProps = { values, setFieldValue, goTo, wizardProps, previous };
	const previousSteps = [nameStep, appliesToStep, typeStep, validationStep];
	const configSteps = rules(stepProps).execute();
	const IsEventSeriesRate =
		values.IsEventSeriesRate || values?.type?.value === "EventSeries";
	const steps = previousSteps
		.map((step) => ({
			step: step(stepProps),
		}))
		//join config steps to the previous steps
		.concat(configSteps)
		//only keep steps that have a summary defined
		.filter(({ step }) => step.summary);

	let expandedSteps = [
		{
			id: "type",
			label: "Type",
			summary: {
				value: IsEventSeriesRate
					? "Recurring / Event Series"
					: values.type.proper,
			},
		},
	];

	for (const { step } of steps) {
		// Skip the "hours" step if IsEventSeriesRate is true, these hours are set by the event series
		if (IsEventSeriesRate && step.id === "oneOff") {
			continue;
		}

		const summary =
			typeof step.summary === "function" ? step.summary() : step.summary;
		if (Array.isArray(summary)) {
			//has multiple summaries in one step
			for (const _summary of summary) {
				expandedSteps.push({
					...step,
					summary: _summary,
					id: _summary.id,
					label: _summary.label,
				});
			}
		} else {
			expandedSteps.push(step);
		}
	}
	let summaries = expandedSteps
		//return the steps
		.map(({ id, label, summary }) => ({
			key: id,
			title: label,
			...summary,
		}));

	//filter out edit function if user doesn't have edit permission
	if (!wizardProps.canManageRates || wizardProps.site.IsDeactivated) {
		summaries = summaries.map((item) => ({
			...item,
			moreInfo: true,
		}));
	}

	return {
		id: "summary",
		label: "Summary",
		render: () => (
			<div>
				<StepTitle>Summary</StepTitle>

				{wizardProps.canManageRates && (
					<AlertMessage
						hidden={!values.validationAttached}
						text="Warning: This rate is attached to a validation beacon or QR code. Updating this rate will update all attached beacons or QR codes to use the new rate configuration. Deleting this rate will remove the validation rate off the beacon or QR code, disabling it until a new rate or validation type is configured."
						backgroundColor="red"
						borderColor="red"
						textColor="white"
					/>
				)}

				<SummaryTable items={summaries} />
			</div>
		),
		footer: () =>
			wizardProps.canManageRates && (
				<WizardNavigation
					leftItems={
						values.mode === "update"
							? [
									<Button
										key="submit"
										color="red"
										onClick={() => {
											setFieldValue("mode", "remove");
											wizardProps.remove();
										}}
										disabled={isSubmitting}
									>
										{IsEventSeriesRate
											? "Delete Rate for entire series"
											: "Delete Rate"}
									</Button>,
							  ]
							: []
					}
					rightItems={[
						!wizardProps.site.IsDeactivated && (
							<Button
								key="submit"
								color="green"
								onClick={handleSubmit}
								disabled={isSubmitting}
								keyStrokeHandler={keyStrokeHandler}
							>
								{values.mode === "update" ? "Update" : "Create"} Rate
							</Button>
						),
					]}
				/>
			),
	};
};
