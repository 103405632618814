import React from "react";
import Button from "../../layout/Button";
import Toggle from "../../layout/Toggle";
import Dropdown from "../../layout/Dropdown";
import Input from "../../layout/Input";
import {
	VARIABLE_TYPE_OPTIONS,
	TERNARY_CONDITION_OPTIONS,
	TIMESTAMP_BUILDER_FROM_OPTIONS,
	TIMESTAMP_BUILDER_UNIT_OPTIONS,
	RESPONSE_METHODS,
	USE_RESPONSE_OPTIONS,
	REPEATER_TYPES,
	OPERATORS,
	AST_NODE,
	LANGUAGE_MODES,
	RULE_OPTIONS_BY_PROP,
	CONDITIONS,
	VARIABLE_TYPES,
} from "./constants";
import { MinusCircle, PlusCircle, ArrowRight, Trash } from "react-feather";
import styled from "styled-components";
import { colours } from "../../../styles";
import { bodyToObject, conditionIsUnary } from "./helpers";
import InputWithIcon from "../../layout/InputWithIcon";
import { StepText } from "../WizardLayout";
import Repeater from "../../layout/Repeater";
import { cloneDeep, isNil } from "lodash";
import { v4 } from "uuid";
import NumberInput from "../../layout/NumberInput";

const ParameterItem = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: baseline;
	gap: 20px;
	flex-direction: row;
`;

export const Icon = styled.div`
	margin-top: 8px;
	margin-left: 10px;
	cursor: pointer;
`;

const delimiterOptions = [
	{ value: ",", label: "Comma" },
	{ value: "\t", label: "Tab" },
];

function CSVEditor(props) {
	const csvSchema = props.values.csvSchema;

	const setSchema = (data) => {
		props.setFieldValue("csvSchema", {
			...csvSchema,
			...data,
		});
	};

	const addColumn = () => {
		const newValues = [...csvSchema.values];
		const newHeaders = [...csvSchema.headers];
		newValues.push("");
		newHeaders.push("");
		setSchema({ headers: newHeaders, values: newValues });
	};

	const removeColumn = () => {
		const newValues = [...csvSchema.values];
		const newHeaders = [...csvSchema.headers];
		newValues.pop();
		newHeaders.pop();
		setSchema({ headers: newHeaders, values: newValues });
	};

	const updateValue = (value, index) => {
		const newValues = [...csvSchema.values];
		newValues[index] = value;
		setSchema({ values: newValues });
	};

	return (
		<div>
			<h3>CSV Schema</h3>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "start",
					gap: 16,
				}}
			>
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						marginRight: "64px",
					}}
				>
					<div
						style={{ fontWeight: "bold", fontSize: "18px", marginRight: "8px" }}
					>
						Headers
					</div>
					<Toggle
						onChange={() =>
							setSchema({ writeHeaders: !csvSchema.writeHeaders })
						}
						checked={csvSchema.writeHeaders}
					/>
				</div>

				<Dropdown
					placeholder={"delimiter"}
					value={delimiterOptions.find((o) => o.value === csvSchema.delimiter)}
					options={delimiterOptions}
					onChange={(value) => setSchema({ delimiter: value.value })}
				/>
				<Input
					type="text"
					name="id"
					placeholder="id"
					value={csvSchema.id}
					onChange={(event) => setSchema({ id: event.target.value })}
					style={{ maxWidth: "200px" }}
				/>
				<Button
					color="green"
					disabled={!csvSchema.id}
					onClick={() => props.onAddToBody(csvSchema.id)}
				>
					Add to Body
				</Button>
			</div>

			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "start",
					gap: 16,
					marginTop: "12px",
				}}
			>
				<Button color="blue" onClick={addColumn}>
					Add Column
				</Button>
				<Button color="blue" onClick={removeColumn}>
					Remove Column
				</Button>
			</div>

			<div
				style={{
					marginTop: "12px",
					display: "flex",
					boxSizing: "border-box",
					justifyContent: "start",
					position: "relative",
					overflow: "auto",
					padding: "12px",
					width: "845px",
				}}
			>
				{csvSchema.values.map((v, idx) => (
					<div key={idx}>
						{csvSchema.writeHeaders && (
							<Cell value={csvSchema.headers[idx]} first={idx === 0} header />
						)}
						<Cell
							first={idx === 0}
							value={csvSchema.values[idx]}
							onChange={(val) => updateValue(val, idx)}
							onFocus={() => {
								setSchema({ selectedCellIndex: idx });
							}}
						/>
					</div>
				))}
			</div>
		</div>
	);
}

function Cell(props) {
	return (
		<div
			style={{
				position: "relative",
				lineHeight: "30px",
				width: "140px",
				borderLeft: props.first ? "1px solid black" : undefined,
				borderTop: "1px solid black",
				borderRight: "1px solid black",
				borderBottom: props.header ? undefined : "1px solid black",
				paddingLeft: "4px",
				paddingRight: "4px",
				background: props.header ? "#ccc" : "white",
			}}
		>
			<input
				style={{
					cursor: "pointer",
					outline: "none",
					border: "none",
					background: "none",
					width: "100%",
					textAlign: "center",
				}}
				value={props.value}
				onChange={(e) => {
					const val = e.target.value;
					props.onChange(val);
				}}
				onFocus={() => props.onFocus?.()}
			/>
		</div>
	);
}

export function makeDefaultMapper(variableType) {
	switch (variableType) {
		case VARIABLE_TYPES.NUMBER:
			return {
				0: {
					value: "No",
					type: {
						value: VARIABLE_TYPES.STRING,
						label: VARIABLE_TYPES.STRING,
					},
				},
				1: {
					value: "Yes",
					type: {
						value: VARIABLE_TYPES.STRING,
						label: VARIABLE_TYPES.STRING,
					},
				},
			};
		case VARIABLE_TYPES.BOOLEAN:
			return {
				true: {
					value: "Yes",
					type: {
						value: VARIABLE_TYPES.STRING,
						label: VARIABLE_TYPES.STRING,
					},
				},
				false: {
					value: "No",
					type: {
						value: VARIABLE_TYPES.STRING,
						label: VARIABLE_TYPES.STRING,
					},
				},
			};
	}
}

function makeNewMapping(variableType, mapping) {
	switch (variableType) {
		case VARIABLE_TYPES.NUMBER: {
			const keys = Object.keys(mapping).sort();
			const key = keys.length ? parseInt(keys[keys.length - 1]) + 1 : 0;
			return {
				[key]: {
					value: "Value",
					type: {
						value: VARIABLE_TYPES.STRING,
						label: VARIABLE_TYPES.STRING,
					},
				},
			};
		}
		case VARIABLE_TYPES.BOOLEAN:
			return null;
	}
}

function PrimitiveMapper({
	mapping,
	setFieldValue,
	values,
	transformationIndex,
}) {
	const valuesToMap = Object.keys(mapping);
	const transformation = values.transformations[transformationIndex];
	const variableType = transformation.variable.type;

	return (
		<Repeater
			items={valuesToMap}
			add={() => {
				const newMapping = makeNewMapping(variableType, mapping);

				setFieldValue(`transformations.${transformationIndex}.mapping`, {
					...mapping,
					...newMapping,
				});
			}}
			subtract={(index) => {
				const key = valuesToMap[index];
				const newMapping = { ...mapping };
				delete newMapping[key];
				setFieldValue(
					`transformations.${transformationIndex}.mapping`,
					newMapping
				);
			}}
			template={(valueToMapFrom, index) => {
				return (
					<ParameterItem
						key={`header${index}`}
						style={{ marginBottom: "15px" }}
					>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "center",
								gap: 8,
							}}
						>
							<div>When</div>
							<NumberInput
								value={valueToMapFrom}
								onChange={(value) => {
									if (value == null) {
										return;
									}

									const newMapping = { ...mapping };
									newMapping[value] = newMapping[valueToMapFrom];
									delete newMapping[valueToMapFrom];

									setFieldValue(
										`transformations.${transformationIndex}.mapping`,
										newMapping
									);
								}}
							/>
							<div>map to</div>
							<Input
								value={mapping[valueToMapFrom].value}
								onChange={(event) => {
									setFieldValue(
										`transformations.${transformationIndex}.mapping.${valueToMapFrom}.value`,
										event.target.value
									);
								}}
							/>
							<div>as</div>
							<Dropdown
								style={{ width: "200px" }}
								placeholder={"type"}
								value={mapping[valueToMapFrom].type}
								options={VARIABLE_TYPE_OPTIONS}
								onChange={(newValue) => {
									setFieldValue(
										`transformations.${transformationIndex}.mapping.${valueToMapFrom}.type`,
										newValue
									);
								}}
							/>
						</div>
					</ParameterItem>
				);
			}}
		/>
	);
}

function BoolMapper({ mapping, setFieldValue, transformationIndex }) {
	const valuesToMap = Object.keys(mapping);

	return (
		<div style={{ display: "flex", flexDirection: "row", gap: 8 }}>
			{valuesToMap.map((key) => (
				<div key={key}>
					<Input
						useLabel={`When ${key} map to...`}
						value={mapping[key].value}
						onChange={(event) => {
							setFieldValue(
								`transformations.${transformationIndex}.mapping.${key}.value`,
								event.target.value
							);
						}}
					/>
					<Dropdown
						style={{ width: "200px" }}
						placeholder={"type"}
						value={mapping[key].type}
						options={VARIABLE_TYPE_OPTIONS}
						onChange={(newValue) => {
							setFieldValue(
								`transformations.${transformationIndex}.mapping.${key}.type`,
								newValue
							);
						}}
					/>
				</div>
			))}
		</div>
	);
}

function Mapper(props) {
	const { values, transformationIndex } = props;

	const transformation = values.transformations[transformationIndex];
	const type = transformation.variable.type;

	if (type === VARIABLE_TYPES.BOOLEAN) {
		return <BoolMapper {...props} />;
	}

	return <PrimitiveMapper {...props} />;
}

function Ternary({ ternary, setFieldValue, transformationIndex, variables }) {
	const findSelectedVariable = (value) => {
		for (const group of variables) {
			for (const variable of group.options) {
				if (variable.value === value) {
					return variable;
				}
			}
		}
		return null;
	};

	return (
		<div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
			<Dropdown
				style={{ width: "200px" }}
				placeholder={"condition"}
				value={TERNARY_CONDITION_OPTIONS.find(
					(option) => option.value === ternary.condition
				)}
				options={TERNARY_CONDITION_OPTIONS}
				onChange={(newValue) => {
					setFieldValue(
						`transformations.${transformationIndex}.ternary.condition`,
						newValue.value
					);
				}}
			/>

			<Dropdown
				placeholder={"When true"}
				value={findSelectedVariable(ternary.ifTrue)}
				options={variables}
				onChange={(newValue) => {
					setFieldValue(
						`transformations.${transformationIndex}.ternary.ifTrue`,
						newValue.value
					);
				}}
			/>

			<Dropdown
				placeholder={"When false"}
				value={findSelectedVariable(ternary.ifFalse)}
				options={variables}
				onChange={(newValue) => {
					setFieldValue(
						`transformations.${transformationIndex}.ternary.ifFalse`,
						newValue.value
					);
				}}
			/>
		</div>
	);
}

function TimestampBuilder({
	timestampBuilder,
	setFieldValue,
	transformationIndex,
}) {
	return (
		<div style={{ display: "flex", flexDirection: "row", gap: 8 }}>
			<Dropdown
				style={{ width: "200px" }}
				placeholder={"from"}
				value={TIMESTAMP_BUILDER_FROM_OPTIONS.find(
					(option) => option.value === timestampBuilder.From
				)}
				options={TIMESTAMP_BUILDER_FROM_OPTIONS}
				onChange={(newValue) => {
					setFieldValue(
						`transformations.${transformationIndex}.timestampBuilder.From`,
						newValue.value
					);
					setFieldValue(
						`transformations.${transformationIndex}.timestampBuilder.Add`,
						{ Days: 1 }
					);
				}}
			/>

			{Object.keys(timestampBuilder?.Add ?? {})
				.filter((unit) => timestampBuilder.Add[unit] != null)
				.map((unit) => (
					<div key={unit}>
						<Input
							type={"number"}
							useLabel={`add ${unit.toLowerCase()}`}
							value={timestampBuilder.Add[unit]}
							onChange={(event) => {
								setFieldValue(
									`transformations.${transformationIndex}.timestampBuilder.Add.${unit}`,
									Number(event.target.value)
								);
							}}
						/>

						<Dropdown
							style={{ width: "200px" }}
							placeholder={"unit"}
							value={TIMESTAMP_BUILDER_UNIT_OPTIONS.find(
								(option) => option.value === unit
							)}
							options={TIMESTAMP_BUILDER_UNIT_OPTIONS}
							onChange={(newValue) => {
								const val = newValue.value;
								setFieldValue(
									`transformations.${transformationIndex}.timestampBuilder.Add.${val}`,
									timestampBuilder.Add[unit]
								);
								setFieldValue(
									`transformations.${transformationIndex}.timestampBuilder.Add.${unit}`,
									undefined
								);
							}}
						/>
					</div>
				))}
		</div>
	);
}

function KeyValuePairs(props) {
	const pairs = Object.entries(bodyToObject(props.body)).map(
		([key, value]) => ({
			key,
			value,
		})
	);

	const onChange = () => {
		const newBody = {};
		for (const { key, value } of pairs) {
			newBody[key] = value;
		}
		props.onChange(JSON.stringify(newBody));
	};

	if (pairs.length === 0) {
		pairs.push({ key: "", value: "" });
		onChange();
	}

	return (
		<div style={{ margin: "16px" }}>
			{pairs.map((pair, index) => (
				<div
					key={index}
					style={{
						display: "flex",
						flexDirection: "row",
						gap: "16px",
						marginBottom: "16px",
					}}
				>
					<Input
						type="text"
						name="key"
						placeholder="key"
						value={pair.key}
						onChange={(event) => {
							pairs[index].key = event.target.value;
							onChange();
						}}
						style={{ width: "200px" }}
					/>

					<ArrowRight />

					<Input
						type="text"
						name="value"
						placeholder="value"
						value={pair.value}
						onChange={(event) => {
							pairs[index].value = event.target.value;
							onChange();
						}}
						style={{ width: "200px" }}
						onFocus={() => props.onCellFocused(pair.key)}
					/>
				</div>
			))}

			<div
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
				}}
			>
				{pairs.length && (
					<Icon>
						<MinusCircle
							onClick={() => {
								pairs.pop();
								onChange();
							}}
							color={colours.red}
						/>
					</Icon>
				)}

				<Icon>
					<PlusCircle
						onClick={() => {
							pairs.push({ key: "", value: "" });
							onChange();
						}}
						color={colours.blue}
					/>
				</Icon>
			</div>
		</div>
	);
}

const OPERATIONS = RULE_OPTIONS_BY_PROP.CONDITION.filter((op) =>
	[CONDITIONS.EQUALS, CONDITIONS.IS_TRUTHY, CONDITIONS.NOT_CONTAINS].includes(
		op.value
	)
);
function ConditionBlock(props) {
	const { test } = props;
	const onChange = () => props.onChange(test);
	const operatorOptions = Object.values(OPERATORS).map((op) => ({
		label: op,
		value: op,
	}));
	if (!test.key) {
		test.key = v4();
	}

	return (
		<>
			{test.Left?.Type === AST_NODE.BINARY_EXPRESSION ? (
				<ConditionBlock
					test={test.Left}
					onChange={(t) => {
						test.Left = t;
						onChange();
					}}
					more={test.Right}
				/>
			) : (
				<div>
					<ParameterItem
						style={{ alignItems: "center", marginTop: "8px" }}
						key={test.key}
					>
						<Dropdown
							style={{ width: "150px" }}
							value={{ label: AST_NODE.PATH, key: AST_NODE.PATH }}
							placeholder={"type"}
							options={[{ label: AST_NODE.PATH, key: AST_NODE.PATH }]}
							onChange={() => {}}
						/>
						<InputWithIcon
							value={test.Left.Name ?? ""}
							onFormat={(value) => {
								test.Left.Name = value;
								onChange();
							}}
						/>
						<Dropdown
							style={{ width: "150px" }}
							value={OPERATIONS.find((cond) => {
								if (test.Left.Operator) {
									return cond.value === test.Left.Operator;
								}
								return cond.value === test.Operator;
							})}
							placeholder={"condition"}
							options={OPERATIONS}
							onChange={(value) => {
								test.Type = conditionIsUnary(value.value)
									? null
									: AST_NODE.BINARY_EXPRESSION;

								if (test.Type === AST_NODE.BINARY_EXPRESSION) {
									test.Operator = value.value;
								} else {
									test.Left.Operator = value.value;
									delete test.Right;
									delete test.Operator;
								}
								test.key = v4();
								onChange();
							}}
						/>

						{!conditionIsUnary(test.Operator) && (
							<InputWithIcon
								value={test.Right?.Name ?? ""}
								onFormat={(value) => {
									if (!test.Right) {
										test.Right = { Type: AST_NODE.VARIABLE };
									}
									test.Right.Name = value;
									onChange();
								}}
							/>
						)}
					</ParameterItem>
				</div>
			)}

			{test.Right?.Left && (
				<div>
					<Dropdown
						style={{ width: "100px", marginTop: "8px" }}
						value={operatorOptions.find((op) => op.value === test.Operator)}
						options={operatorOptions}
						onChange={(option) => {
							test.Operator = option.value;
							onChange();
						}}
						key={test.key}
					/>

					<ConditionBlock
						test={test.Right}
						onChange={(t) => {
							test.Right = t;
							onChange();
						}}
					/>
				</div>
			)}
		</>
	);
}

function FilterResponse(props) {
	const filterIndex = props.values.response.findIndex(
		(r) => r.method === RESPONSE_METHODS.FILTER
	);
	const test = props.values.response[filterIndex]?.Predicate?.Test;

	const setTest = (t) => {
		props.setFieldValue(`response.${filterIndex}.Predicate.Test`, t);
	};

	const createNode = () => ({ Left: { Name: "", Type: AST_NODE.PATH } });
	const addBlock = () => {
		if (test.Right) {
			setTest({
				Left: test,
				Right: createNode(),
				Type: AST_NODE.BINARY_EXPRESSION,
				Operator: OPERATORS.AND,
			});
		} else {
			setTest({
				...test,
				Type: AST_NODE.BINARY_EXPRESSION,
				Right: createNode(),
			});
		}
	};

	return (
		<div>
			<Toggle
				labelElement={
					<StepText style={{ flex: "auto 0" }}>Filter Response</StepText>
				}
				checked={props.values.filterResponse}
				onChange={(isChecked) => {
					if (isChecked) {
						props.setFieldValue("response", [
							...props.values.response,
							{
								method: RESPONSE_METHODS.FILTER,
								path: "",
								Predicate: null,
							},
						]);
					} else if (!isChecked && filterIndex) {
						const items = [...props.values.response];
						items.splice(filterIndex, 1);
						props.setFieldValue("response", items);
					}
					props.setFieldValue("filterResponse", isChecked);
				}}
			/>

			{props.values.filterResponse && filterIndex >= 0 && (
				<div style={{ marginLeft: "1rem" }}>
					<div style={{ marginBottom: "1rem" }}>
						{
							"*Nested paths can be separated with a '.', e.g. OuterProperty.InnerProperty"
						}
					</div>

					<ParameterItem style={{ marginBottom: "16px" }}>
						<div style={{ fontWeight: "600" }}>
							{`Return the response located at ${
								props.values.languageMode === LANGUAGE_MODES.SOAP
									? "(starting from inside soap:Body)"
									: ""
							}`}
						</div>
						<InputWithIcon
							value={props.values.response[filterIndex].path}
							onFormat={(value) => {
								props.setFieldValue(`response.${filterIndex}.path]`, value);
							}}
							placeholder={"path"}
							width={"360px"}
						/>
					</ParameterItem>

					<Toggle
						label={"Conditional Filtering"}
						checked={props.values.conditionalResponseFiltering}
						onChange={(isChecked) => {
							props.setFieldValue(
								`response.${filterIndex}.Predicate`,
								isChecked ? { Test: createNode() } : null
							);

							props.setFieldValue("conditionalResponseFiltering", isChecked);
						}}
					/>

					{props.values.conditionalResponseFiltering && test && (
						<>
							<div style={{ fontWeight: "600" }}>if</div>
							<ConditionBlock
								test={cloneDeep(test)}
								onChange={(t) => setTest(t)}
								onAddBlock={addBlock}
							/>
							<div
								style={{
									marginTop: "0.5rem",
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									gap: "0.5rem",
								}}
							>
								<Trash
									style={{ cursor: "pointer", marginTop: "8px" }}
									onClick={() => setTest(createNode())}
									color={colours.red}
								/>
								<PlusCircle
									style={{ cursor: "pointer", marginTop: "8px" }}
									onClick={addBlock}
									color={colours.blue}
								/>
							</div>
						</>
					)}
				</div>
			)}
		</div>
	);
}

function ReturnResponse(props) {
	const responseIndex = props.values.response.findIndex(
		(r) => r.method === RESPONSE_METHODS.RETURN_RESPONSE
	);
	const response = props.values.response[responseIndex];
	if (!response) {
		return null;
	}

	return (
		<ParameterItem style={{ marginBottom: "16px" }}>
			<div>Use the response as</div>
			<Dropdown
				style={{ width: "184px" }}
				placeholder={"internal reference"}
				value={USE_RESPONSE_OPTIONS.find(
					(opts) => opts.value === response.variable
				)}
				options={USE_RESPONSE_OPTIONS}
				onChange={(value) => {
					props.setFieldValue(
						`response.${responseIndex}.variable`,
						value?.value
					);
				}}
			/>
		</ParameterItem>
	);
}

const FlexRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: baseline;
	flex-wrap: wrap;
	margin-top: 10px;
	margin-bottom: 10px;
	gap: 20px;
`;

export function RepeaterStepItem({
	index,
	repeatedItem,
	config,
	setFieldValue,
	optionsMap,
}) {
	return repeatedItem.mode === "edit"
		? Object.keys(optionsMap).map((prop) => {
				const model = optionsMap[prop];
				const prefix = model.prefix;
				const options =
					model.options?.map((t) => {
						if (!isNil(t.value) && !isNil(t.label)) {
							return t;
						}
						return {
							value: t,
							label: t,
						};
					}) || [];

				return (
					<>
						{prefix && (
							<span
								style={{
									fontSize: "20px",
									marginTop: "10px",
								}}
							>
								{prefix}
							</span>
						)}
						{model.type === REPEATER_TYPES.SELECT && (
							<Dropdown
								key={prop}
								placeholder={model.placeholder || prop}
								value={options.find((o) => o.value === repeatedItem[prop])}
								options={options ?? []}
								onChange={(value) => {
									setFieldValue(
										`${config.name}.${index}.${prop}`,
										value?.value
									);
								}}
							/>
						)}

						{model.type === REPEATER_TYPES.LITERAL && (
							<Input
								onChange={(event) => {
									const val = event.target.value;
									setFieldValue(`${config.name}.${index}.${prop}`, val);
								}}
								value={repeatedItem[prop]}
							/>
						)}
					</>
				);
		  })
		: config.savedView && (
				<div style={{ marginTop: "10px" }}>
					{config.savedView(repeatedItem, index)}
				</div>
		  );
}

function RepeaterStepSection({ key, item, setFieldValue, items, values }) {
	return (
		<>
			{item.title && (
				<StepText>
					<FlexRow style={{ alignItems: "center" }}>
						{item.title}
						{item.subTitle && (
							<span style={{ fontSize: "18px", fontWeight: "200" }}>
								{item.subTitle}
							</span>
						)}
					</FlexRow>
				</StepText>
			)}

			<Repeater
				style={{ marginLeft: item.marginLeft || "20px" }}
				minusMode="all"
				disableAdd={item.disableAdd}
				items={items}
				controlPlacement={item.controlPlacement || "right"}
				edit={(index) => {
					setFieldValue(`${item.name}.${index}.mode`, "edit");
				}}
				save={(index) => {
					setFieldValue(`${item.name}.${index}.mode`, "view");
				}}
				add={() => {
					setFieldValue(`${item.name}`, [
						...values[item.name].map((other) => ({ ...other, mode: "" })),
						item.newModel(),
					]);
				}}
				subtract={(index) => {
					const _items = [...values[item.name]];
					if (index > -1) _items.splice(index, 1);
					setFieldValue(`${item.name}`, _items);
				}}
				template={(repeatedItem, index) => {
					const optionsMap = item.getOptionsMap(repeatedItem, index);
					return (
						<ParameterItem>
							<RepeaterStepItem
								key={`${key}-item-${index}`}
								index={index}
								repeatedItem={repeatedItem}
								config={item}
								setFieldValue={setFieldValue}
								optionsMap={optionsMap}
							/>
						</ParameterItem>
					);
				}}
			/>
		</>
	);
}

export {
	CSVEditor,
	Mapper,
	Ternary,
	TimestampBuilder,
	KeyValuePairs,
	ParameterItem,
	FilterResponse,
	ReturnResponse,
	RepeaterStepSection,
};
