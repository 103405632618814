import { isBoolean, isNumber, isString } from "lodash";
import { HANDLER_ACTIONS, VARIABLE_TYPES, CONDITIONS } from "./constants";

function bodyToObject(body) {
	try {
		return JSON.parse(body) || {};
	} catch (e) {
		return {};
	}
}

function conditionIsUnary(operator) {
	return [
		CONDITIONS.IS_EMPTY,
		CONDITIONS.IS_TRUTHY,
		CONDITIONS.NOT_SET,
	].includes(operator);
}

export const typeByTransformation = {
	ConvertToNegativeFloat: "Float",
	ConvertToNegativeInteger: "Float",
	ConvertToString: "String",
	Cents: "Float",
	Format: "String",
	AddHours: "Timestamp",
	GenerateRandomString: "String",
	FromTemplate: "String",
};

const formatDescription = {
	UTC: "in UTC",
	Timezone: "using Site Timezone",
};

const descriptionByTransformation = {
	ConvertToString: (transform) =>
		`Converted ${
			transform.variable.label || transform.variable.value
		} to String`,
	ConvertToNegativeFloat: (transform) =>
		`Converted ${
			transform.variable.label || transform.variable.value
		} to negative float value`,
	ConvertToNegativeInteger: (transform) =>
		`Converted ${
			transform.variable.label || transform.variable.value
		} to negative integer value`,
	Cents: (transform) =>
		`Converted ${
			transform.variable.label || transform.variable.value
		} from dollars to cents`,
	Format: (transform) =>
		`Formatted as ${transform.template} from ${transform.variable.label} ${
			formatDescription[transform.format.value]
		}`,
	AddHours: (transform) => {
		return `Adding ${transform.hours} Hour${
			transform.hours == 1 ? "" : "s"
		} to the ${transform.variable?.label}`;
	},
	GenerateRandomString: (transform) =>
		`Generating a random ${transform.characterLength} character string at runtime.`,
	FromTemplate: () => `Created from a string template.`,
};

const byTransformModel = (transform) => ({
	type: typeByTransformation[transform.transformation.value],
	description: `${
		descriptionByTransformation[transform.transformation.value]?.(transform) ||
		transform.label ||
		transform.value
	}`,
});

export const transformModelByType = {
	Timestamp: byTransformModel,
	Integer: byTransformModel,
	Float: byTransformModel,
	Action: byTransformModel,
	String: (transform) => ({
		type: "String",
		description: `Base 64 encoding of ${
			transform.variable?.label || transform.variable?.name
		}`,
	}),
};

export function mapTransformationsToVariables(transformations) {
	if (!transformations) {
		return [];
	}

	return transformations
		.filter((trn) => trn.name)
		.map((transform) => {
			const trns = transformModelByType[transform.variable?.type]?.(transform);
			return {
				value: transform.name,
				label: transform.label,
				description: trns?.description ? ` ${trns?.description}` : "",
				type: trns?.type || "String",
			};
		});
}

function getAllVariableGroups(values) {
	const groups = [];

	if (values.userInputVariables) {
		groups.push({
			name: "User Input",
			color: "blue",
			variables: values.userInputVariables.map((iv) => ({
				value: iv.Key,
				label: iv.Key,
				description: iv.Description,
				type: iv.Type,
			})),
		});
	}

	if (values.hasTransformations) {
		groups.push({
			name: "Transform",
			color: "purple",
			variables: mapTransformationsToVariables(values.transformations),
		});
	}

	groups.push(...values.variableGroups);

	return groups;
}

function inferVariableType(x) {
	if (isNumber(x)) {
		return x % 1 === 0 ? VARIABLE_TYPES.NUMBER : VARIABLE_TYPES.FLOAT;
	}

	if (isString(x)) {
		return VARIABLE_TYPES.STRING;
	}

	if (isBoolean(x)) {
		return VARIABLE_TYPES.BOOLEAN;
	}

	return null;
}

function newInsertProperty() {
	return {
		objectProp: "",
		prop: "",
		propSource: "",
		mode: "edit",
	};
}

function newInsertHandler() {
	return {
		object: "",
		mode: "edit",
		action: HANDLER_ACTIONS.INSERT,
		properties: [newInsertProperty()],
	};
}

export {
	bodyToObject,
	conditionIsUnary,
	getAllVariableGroups,
	inferVariableType,
	newInsertHandler,
	newInsertProperty,
};
