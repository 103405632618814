import gql from "graphql-tag";
import { useQueryData } from "../../hooks";

function GetBrandByOrganizationId(organizationId) {
	const {
		data: { getOrganizationById },
	} = useQueryData(
		gql`
			query ($organizationId: Int!) {
				getOrganizationById(organizationId: $organizationId) {
					Brands {
						BrandID
						LogoDark
						CompanyName
						MainColor
						IconLogo
						SecondaryColor
					}
				}
			}
		`,
		{ organizationId: organizationId }
	);
	return getOrganizationById?.Brands?.map((brand) => ({
		value: brand?.BrandID,
		label: brand?.CompanyName,
	}));
}

export { GetBrandByOrganizationId };
