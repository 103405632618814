import * as constants from "./constants";
import * as errors from "./errors";
import * as format from "./format";
import * as permissions from "./permissions";
import * as routing from "./routing";
import * as site from "./site";
import * as user from "./user";
import * as validate from "./validate";
import * as crypto from "./crypto";
import { isObject } from "lodash";

function flattenObject(obj, prefix = "") {
	const result = [];

	for (const [key, value] of Object.entries(obj)) {
		const fullKey = prefix ? `${prefix}.${key}` : key;
		if (isObject(value) && value !== null) {
			result.push(...flattenObject(value, fullKey));
		} else {
			result.push({ key: fullKey, value });
		}
	}

	return result;
}

export {
	constants,
	errors,
	format,
	permissions,
	routing,
	site,
	user,
	validate,
	crypto,
	flattenObject,
};
